import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import './AllComponents.css'
import Navbar from '../NavBar/navbar'
import LoginForm from '../Forms/login'
import ForgetPassword from '../Forms/ForgetPassword'
import ConfirmPassword from '../Forms/ConfirmPassword'

import SignupForm from '../Forms/signup'
import Home from '../Home/home'
import Booking1 from '../Booking/Booking1'
import Services from '../Services/Services'
import ImageDesc from '../ImageDesc/imageDesc'
import Team from '../OurTeam/Team'
import VideoDesc from '../VideoDesc/videoDesc'
import Testimonial from '../Testimonials/testimonial'
import FAQs from './../FAQs/FAQs'
import NewsLetter from '../NewsLetter/news'
import Find from '../FindUs/Find'
import Footer from '../Footer/footer'
import MergePrivacyComponent from './MergePrivacyComponent'
import MergeTermsComponents from './MergeTermsComponents'
import Modal from '../Modal/Modal'
import useModal from '../Modal/useModal'
import Booking2 from '../Booking/Booking2'
import Booking3 from '../Booking/Booking3'
import Booking4 from '../Booking/Booking4'
import Booking5 from '../Booking/Booking5'
import Booking6 from '../Booking/Booking6'
import Booking7 from '../Booking/Booking7'
import AboutUs from '../Booking/About US/AboutUs'
import { BookingProvider } from '../BookingContext/DataContext'
import ReturnURL from '../Booking/PaymentURL/ReturnURL'
import CancelURL from '../Booking/PaymentURL/CancelURL'
import Progress from '../Progress/progress'
import ProgressBar from '../ProgressBar/ProgressBar'
import ProgressBarCopy from '../ProgressBarCopy/ProgressBar'
import UserSubscription from '../UserSubscription/UserSubscription'
import Booking8 from '../Booking/Booking8'
import TermsAndConditions from '../Booking/TermsAndConditions/TermsAndConditions'
import Booking9 from '../Booking/Booking9'

function App() {
  return (
    <BookingProvider>
      <Router>
        <div>
          <ConditionalNavbar />
          <ConditionalModal />
          <Routes>
            <Route path="/" element={<AllComponents />} />
            <Route path="/about-booking" element={<AboutUs />} />
            <Route path="/booking_two" element={<Booking2 />} />
            <Route path="/booking_three" element={<Booking3 />} />
            <Route path="/booking_four" element={<Booking4 />} />
            <Route path="/booking_five" element={<Booking5 />} />
            <Route path="/booking_six" element={<Booking6 />} />
            <Route path="/booking_seven" element={<Booking7 />} />
            <Route path="/booking_eight" element={<Booking8 />} />
            <Route path="/booking_nine" element={<Booking9 />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/signup" element={<SignupForm />} />
            <Route path="/forget-password" element={<ForgetPassword />} />
            <Route path="/confirm-password" element={<ConfirmPassword />} />

            <Route path="/privacy-policy" element={<MergePrivacyComponent />} />
            <Route
              path="/terms-conditions"
              element={<MergeTermsComponents />}
            />
            <Route path="/return-url" element={<ReturnURL />} />
            <Route path="/cancel-url" element={<CancelURL />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
          </Routes>
        </div>
      </Router>
    </BookingProvider>
  )
}

function ConditionalModal() {
  const { isOpen, closeModal } = useModal()

  const location = useLocation()
  const hideModalPaths = [
    '/login',
    '/signup',
    '/privacy-policy',
    '/terms-conditions',
    '/about-booking',

    '/booking_two',
    '/booking_three',
    '/booking_four',
    '/booking_five',
    '/booking_six',
    '/booking_seven',
    '/booking_eight',
    '/booking_nine',
    '/return-url',
    '/cancel-url',

    '/terms-and-conditions',
  ]

  return !hideModalPaths.includes(location.pathname) ? (
    <Modal isOpen={isOpen} onClose={closeModal} />
  ) : null
}

function ConditionalNavbar() {
  const location = useLocation()

  const hideNavbarPaths = [
    '/login',
    '/signup',
    '/privacy-policy',
    '/terms-conditions',
    '/about-booking',

    '/booking_two',
    '/booking_three',
    '/booking_three',
    '/booking_four',
    '/booking_five',
    '/booking_six',
    '/booking_seven',
    '/booking_eight',
    '/booking_nine',

    '/return-url',
    '/cancel-url',
    '/terms-and-conditions',
  ]

  return !hideNavbarPaths.includes(location.pathname) ? <Navbar /> : null
}

function AllComponents() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])
  return (
    <div>
      <Home />
      <Booking1 />
      <UserSubscription />
      <Services />
      <ImageDesc />
      <Team />
      <VideoDesc />
      <Testimonial />
      <FAQs />
      {/* <ProgressBar />
      <ProgressBarCopy /> */}
      {/* <Progress /> */}
      <NewsLetter />
      <Find />
      <Footer />
    </div>
  )
}

export default App
