import React, { useEffect, useState } from 'react'
import './Booking3.css'
import { useBooking } from '../BookingContext/DataContext'
import { useNavigate } from 'react-router-dom'
import OpenNewWindow from './ExampleComp/NewWindow'
import { API_BASE_URL } from '../../Config/Config'
import { NavLink } from 'react-router-dom'
import NewWindowTAC from './ExampleComp/NewWindowTAC'
import BookingLoader from '../BookingLoader/LoadingAndError'

const Booking3 = () => {
  const { bookingData, setBookingData } = useBooking()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const [email, setEmail] = useState(bookingData.email || '')
  const [emailError, setEmailError] = useState('')

  const handleBack = () => {
    navigate('/booking_two')
  }

  const handleNext = async () => {
    if (!email) {
      setEmailError('Email is required')
      return
    }

    if (!validateEmail(email)) {
      setEmailError('Enter a valid email')
      return
    }
    setLoading(true)
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Booking/RegisterOption`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            // "ngrok-skip-browser-warning": "true",
          },
          body: JSON.stringify({
            email: bookingData.email,
            quoteCode: bookingData.quoteCode,
            productName: 'Product Name',
            productDescription: 'Product Description',
            productPrice: bookingData.totalDaysCost,
            totalCost: bookingData.totalCostAll,
          }),
        }
      )
      if (!response.ok) {
        throw new Error('Failed to fetch quote')
      }
      const data = await response.json()

      setLoading(false)
      navigate('/booking_four')
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  // function handleRegisterOptionChange(e) {
  //   const { name, value } = e.target;
  //   setBookingData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // }

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    if (!e.target.value) {
      setEmailError('Email is required')
    } else if (!validateEmail(e.target.value)) {
      setEmailError('Enter a valid email')
    } else {
      setEmailError('')
    }
  }

  useEffect(() => {
    setBookingData((prevData) => ({
      ...prevData,
      email: email,
    }))
  }, [email])
  console.log(bookingData.email)

  return (
    <div className="booking-container-background">
      {loading && <BookingLoader />}

      <div className="booking-navbar-internal">
        <ul className="internal-navbar">
          <li>
            <NavLink to="/">Back to Home</NavLink>
          </li>
          <li className="nabar-item-1-booking-3">
            <a className="active1" to="#news">
              Get a Quote
            </a>
          </li>
          <li className="nabar-item-2-booking-3">
            <a href="#contact"> Available Products</a>
          </li>
          <li className="nabar-item-3-booking-3">
            <a href="#about">Products</a>
          </li>
          <li className="nabar-item-4-booking-3">
            <a href="#about">Booking Details</a>
          </li>
          <li className="nabar-item-5-booking-3">
            <a href="#about">Payment</a>
          </li>
          <li className="nabar-item-6-booking-3">
            <a href="#about">Payment Details</a>
          </li>
        </ul>
      </div>
      <div className="booking-container">
        {/* <div className="status">
          <span>Status: </span> <span> Get a Quote </span>&gt;
          <span>
            {" "}
            <b>Available Products</b>{" "}
          </span>
          &gt;<span> Product Options </span>&gt;
          <span> Booking Details </span>
        </div> */}
        <div className="booking-details">
          <h2>Booking Details</h2>
          <div className="detail-item">
            <strong>Location:</strong> <span>{bookingData.airportName}</span>
          </div>
          <div className="field-site-booking3">
            <label className="site-box-field-booking3" htmlFor="site">
              Site:
            </label>
            <OpenNewWindow />
          </div>
          <div className="detail-item">
            <strong>Parking Start:</strong>{' '}
            <span>{bookingData.parkingStart}</span>
          </div>
          <div className="detail-item">
            <strong>Parking End:</strong> <span>{bookingData.parkingEnd}</span>
          </div>
          <div className="detail-item">
            <strong>Product:</strong>{' '}
            <span>
              {bookingData.airportCode} Flexible
              <NewWindowTAC />
            </span>
          </div>
          <div className="detail-item">
            <strong>Price:</strong>{' '}
            <span>
              {bookingData.totalDaysCost}£
              <div className="main-text">
                For more information on how to use the ticket please use the
                link(s) above. (Opens in a new window.)
              </div>
            </span>
          </div>

          {/* <div className="detail-item">
          <strong>Price:</strong> <span>{bookingData.totalDaysCost}£</span>
        </div> */}
        </div>
        {/* <div className="detail-item">
        <span>
          For more information on how to use the ticket please use the link(s)
          above. (Opens in a new window.)
        </span>
      </div> */}
        <div className="registration">
          <h2>Email</h2>
          {/* <div className="detail-item">
          <strong>Register Option:</strong>
          <div>
            <div className="register-option">
              <input
                type="radio"
                name="registerOption"
                value="Login"
                checked={bookingData.registerOption === "Login"}
                onChange={handleRegisterOptionChange}
              />
              <label htmlFor="login">Login to existing account</label>
            </div>
            <div className="register-option">
              <input
                type="radio"
                name="registerOption"
                value="Register"
                checked={bookingData.registerOption === "Register"}
                onChange={handleRegisterOptionChange}
              />
              <label htmlFor="register">Register new account</label>
            </div>
            <div className="register-option">
              <input
                type="radio"
                name="registerOption"
                value="Guest"
                checked={bookingData.registerOption === "Guest"}
                onChange={handleRegisterOptionChange}
              />
              <label htmlFor="guest">Continue as guest</label>
            </div>
            <p>Please login or register to save time on future purchases.</p>
            <p>
              Continue as guest if you do not wish your details to be saved.
            </p>
          </div>
        </div> */}
          <div className="email-field">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <span className="asterisk">*</span>
            {emailError && (
              <p className="error-message-booking3">{emailError}</p>
            )}
          </div>
          <p className="make-sure">
            Please ensure this email address is correct. It is used to send the
            booking receipt and instructions from Askaii Parking.
          </p>
        </div>
        <div className="actions">
          <button onClick={handleBack}>Previous</button>
          <button onClick={handleNext}>Continue</button>
        </div>
      </div>
    </div>
  )
}

export default Booking3
