import './Booking7.css'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { API_BASE_URL } from '../../Config/Config'
import { useBooking } from '../BookingContext/DataContext'
import { NavLink } from 'react-router-dom'
import BookingLoader from '../BookingLoader/LoadingAndError'

const Booking7 = () => {
  const navigate = useNavigate()
  const { bookingData, setBookingData, cardData, setCardData } = useBooking()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const [errors, setErrors] = useState({})

  const handleCardChange = (e) => {
    const { name, value } = e.target

    let newValue = value
    if (name === 'cardNumber') {
      newValue = value.replace(/\D/g, '').replace(/(\d{4})(?=\d)/g, '$1 ')
    } else if (name === 'expirationMonth' || name === 'expirationYear') {
      newValue = value.replace(/\D/g, '')
    } else if (name === 'cvc') {
      newValue = value.replace(/\D/g, '')
    } else if (name === 'name') {
      newValue = value.replace(/[^a-zA-Z\s]/g, '')
    }

    setCardData((prevData) => ({
      ...prevData,
      creditCard: {
        ...prevData.creditCard,
        [name]: newValue,
      },
    }))
  }

  const handleCardNumberChange = (e) => {
    let { value } = e.target
    // Remove any non-digit characters
    const rawValue = value.replace(/\D/g, '')

    // Insert space after every 4 digits for display purposes
    const formattedValue = rawValue.replace(/(\d{4})(?=\d)/g, '$1 ')

    setCardData((prevData) => ({
      ...prevData,
      creditCard: {
        ...prevData.creditCard,
        cardNumber: rawValue,
      },
    }))
  }

  const handleExpiryChange = (e) => {
    let { value } = e.target
    // Remove any non-numeric characters except slash
    value = value.replace(/\D/g, '')

    // Insert slash after every 2 digits (MM/YY)
    if (value.length > 2) {
      value = value.slice(0, 2) + '/' + value.slice(2)
    }

    setCardData((prevData) => ({
      ...prevData,
      creditCard: {
        ...prevData.creditCard,
        expirationMonth: value.slice(0, 2),
        expirationYear: value.slice(3, 5),
      },
    }))
  }

  const validate = () => {
    const newErrors = {}
    const { name, cardNumber, expirationMonth, expirationYear, cvc } =
      cardData.creditCard

    // Validate card number
    const cleanedCardNumber = cardNumber.replace(/-/g, '')
    if (cleanedCardNumber.length !== 16) {
      newErrors.cardNumber = 'Card number must be 16 digits'
    } else if (!luhnCheck(cleanedCardNumber)) {
      newErrors.cardNumber = 'Invalid card number'
    }

    // Validate expiry
    if (expirationMonth.length !== 2 || expirationYear.length !== 2) {
      newErrors.expiry = 'Expiry date must be in MM/YY format'
    } else {
      const month = parseInt(expirationMonth, 10)
      const year = parseInt(expirationYear, 10)
      if (month < 1 || month > 12) {
        newErrors.expirationMonth = 'Invalid expiry month'
      }
      if (year < new Date().getFullYear() % 100) {
        newErrors.expirationYear = 'Card has expired'
      }
    }

    // Validate security code
    if (cvc.length < 3 || cvc.length > 5) {
      newErrors.cvc = 'Security code must be correct'
    }

    // Validate cardholder name
    if (name.trim() === '') {
      newErrors.name = 'Cardholder name cannot be empty'
    }

    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const luhnCheck = (num) => {
    let arr = (num + '')
      .split('')
      .reverse()
      .map((x) => parseInt(x))
    let sum = arr.reduce((acc, val, i) => {
      if (i % 2 === 0) {
        return acc + val
      } else {
        let double = val * 2
        return acc + (double > 9 ? double - 9 : double)
      }
    }, 0)
    return sum % 10 === 0
  }

  const handleBack = () => {
    navigate('/booking_six')
  }

  const handleNext = async (e) => {
    e.preventDefault()
    if (validate()) {
      setLoading(true)
      const { name, cardNumber, expirationMonth, expirationYear, cvc } =
        cardData.creditCard
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Booking/AddCustomer`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email: bookingData.email,
              name: bookingData.userSurName,
              creditCard: {
                name,
                cardNumber: cardNumber.replace(/-/g, ''),
                expirationYear,
                expirationMonth,
                cvc,
              },
            }),
          }
        )
        if (!response.ok) {
          throw new Error('Failed to fetch quote')
        }
        const data = await response.json()
        setBookingData((prevData) => ({
          ...prevData,
          customerId: data.customerId,
        }))
        setLoading(false)
        navigate('/booking_eight')
      } catch (err) {
        console.error(err.message)
        setLoading(false)
      }
    }
  }

  return (
    <div className="booking7-container-background">
      {loading && <BookingLoader />}

      <div className="booking-navbar-internal">
        <ul className="internal-navbar">
          <li>
            <NavLink to="/">Back to Home</NavLink>
          </li>
          <li className="nabar-item-1-booking-7">
            <a className="active1" href="#news">
              Get a Quote
            </a>
          </li>
          <li className="nabar-item-2-booking-7">
            <a href="#contact">Available Products</a>
          </li>
          <li className="nabar-item-3-booking-7">
            <a href="#about">Products</a>
          </li>
          <li className="nabar-item-4-booking-7">
            <a href="#about">Booking Details</a>
          </li>
          <li className="nabar-item-5-booking-7">
            <a href="#about">Payment</a>
          </li>
          <li className="nabar-item-6-booking-7">
            <a href="#about">Payment Details</a>
          </li>
        </ul>
      </div>
      <div className="booking7-container">
        <h2 className="booking7-header">Payment</h2>
        <div className="booking7-detail-item">
          <strong>Transaction Type:</strong>
          <span>Booking</span>
        </div>
        <div className="booking7-detail-item">
          <strong>Charges:</strong>
          <span>
            {bookingData?.airportCode} Flexible ({bookingData?.totalDays}{' '}
            {bookingData?.totalDays === 1 ? 'Day' : 'Days'})
          </span>
        </div>
        <div className="booking7-detail-item total-cost-item7">
          <strong>Total Cost:</strong>
          <span>{bookingData?.totalCostAll}</span>
        </div>
        <div className="booking7-detail-item">
          <strong>Payment Method:</strong>
          <span>Card</span>
        </div>

        <h2 className="booking7-header">Billing Address</h2>
        <div className="billing-address-section7">
          <div className="booking7-detail-item">
            <strong>Billing Address:</strong>
            <div className="billing-address-details7">
              <p>{`${bookingData?.userTitle} ${bookingData?.userFirstName} ${bookingData?.userSurName}`}</p>
              <p>{bookingData?.postCode}</p>
              <p>{bookingData?.address}</p>
              <p>{bookingData?.town}</p>
              <p>{bookingData?.county}</p>
            </div>
          </div>
        </div>

        <h2 className="booking7-header">Card Details</h2>
        <div className="card-details-section7">
          <div className="payment-details-header7">Payment Details</div>
          <div className="booking7-detail-item">
            <strong>Card Number:</strong>
            <input
              type="text"
              name="cardNumber"
              value={cardData.creditCard.cardNumber.replace(
                /(\d{4})(?=\d)/g,
                '$1 '
              )}
              onChange={handleCardNumberChange}
              maxLength="19"
            />
            <span className="asterisk">*</span>

            {errors.cardNumber && (
              <p className="booking5-error-message">{errors.cardNumber}</p>
            )}
          </div>
          <div className="booking7-detail-item">
            <strong>Expiry:</strong>
            <input
              type="text"
              name="expiry"
              value={`${cardData.creditCard.expirationMonth || ''}/${
                cardData.creditCard.expirationYear || ''
              }`}
              onChange={handleExpiryChange}
              maxLength="5"
            />
            <span className="asterisk">*</span>

            {errors.expiry && (
              <p className="booking5-error-message">{errors.expiry}</p>
            )}
          </div>
          <div className="booking7-detail-item">
            <strong>Security Code:</strong>
            <input
              type="text"
              name="cvc"
              value={cardData.creditCard.cvc}
              onChange={handleCardChange}
              maxLength="4"
            />
            <span className="asterisk">*</span>

            {errors.cvc && (
              <p className="booking5-error-message">{errors.cvc}</p>
            )}
          </div>
          <div className="booking7-detail-item">
            <strong>Cardholder Name:</strong>
            <input
              type="text"
              name="name"
              value={cardData.creditCard.name}
              onChange={handleCardChange}
            />
            <span className="asterisk">*</span>
            {errors.name && (
              <p className="booking5-error-message">{errors.name}</p>
            )}
          </div>
        </div>

        <div className="actions7">
          <button type="button" onClick={handleBack}>
            Previous
          </button>
          <button type="button" onClick={handleNext} className="pay-now-btn7">
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

export default Booking7
