import React, { useEffect, useState } from 'react'
import './Booking4.css'
import OpenNewWindow from './ExampleComp/NewWindow'
import { useNavigate } from 'react-router-dom'
import { useBooking } from '../BookingContext/DataContext'
import { API_BASE_URL } from '../../Config/Config'
import { NavLink } from 'react-router-dom'
import BookingLoader from '../BookingLoader/LoadingAndError'

const Booking4 = () => {
  const { bookingData, setBookingData } = useBooking()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({
    outboundTerminalError: '',
    inboundTerminalError: '',
  })

  const [inboundTerminals, setInboundTerminals] = useState(
    bookingData.inboundTerminals || []
  )
  const [outboundTerminals, setOutboundTerminals] = useState(
    bookingData.outboundTerminals || []
  )
  const [carCleanService, setCarCleanService] = useState(
    bookingData.carCleanService || []
  )
  const [electricChargeService, setElectricChargeService] = useState(
    bookingData.carCleanService || []
  )

  const [fetchedTerminals, setFetchedTerminals] = useState(false)
  const [fetchedServices, setFetchedServices] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (!fetchedTerminals) {
      const fetchTerminals = async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/Terminal/GetAllbyAirPort/${bookingData.airportId}`,
            {
              headers: {
                'ngrok-skip-browser-warning': 'true',
              },
            }
          )
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }

          const data = await response.json()
          const inbounds = data.filter(
            (terminal) => terminal.terminalType === 'InboundTerminal'
          )
          const outbounds = data.filter(
            (terminal) => terminal.terminalType === 'OutBoundTerminal'
          )
          setInboundTerminals(inbounds)
          setOutboundTerminals(outbounds)
          setFetchedTerminals(true) // Mark as fetched
          setBookingData((prevData) => ({
            ...prevData,
            outboundTerminals: outbounds,
            inboundTerminals: inbounds,
            terminalState: true,
          }))
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error)
        }
      }
      fetchTerminals()
    }
  }, [fetchedTerminals, bookingData.airportId, setBookingData])

  useEffect(() => {
    if (!fetchedServices) {
      const fetchServices = async () => {
        try {
          const response = await fetch(
            `${API_BASE_URL}/api/Services/ServiceByAirport/${bookingData.airportId}`,
            {
              headers: {
                'ngrok-skip-browser-warning': 'true',
              },
            }
          )
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }

          const data = await response.json()
          const carClean = data.filter(
            (service) => service.serviceType === 'Car Clean'
          )
          const electricCharge = data.filter(
            (service) => service.serviceType === 'electricCharge'
          )
          setCarCleanService(carClean)
          setElectricChargeService(electricCharge)
          setFetchedServices(true) // Mark as fetched

          setBookingData((prevData) => ({
            ...prevData,
            carCleanService: carClean,
            electricChargeService: electricCharge,
            serviceState: true,
          }))
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error)
        }
      }
      fetchServices()
    }
  }, [fetchedServices, bookingData.airportId, setBookingData])

  const handleOutBoundTerminalChange = (e) => {
    const selectedId = e.target.value
    const selectedOutBoundTerminal = outboundTerminals.find(
      (outboundTerminal) => outboundTerminal.terminalID === parseInt(selectedId)
    )
    const costDifference =
      selectedOutBoundTerminal.terminalCost ||
      0 - bookingData.outBoundTerminalCost

    setBookingData((prevData) => ({
      ...prevData,
      outBoundTerminalName: selectedOutBoundTerminal?.terminalName || '',
      outBoundTerminalId: selectedOutBoundTerminal?.terminalID || '',
      outBoundTerminalCost: selectedOutBoundTerminal?.terminalCost || 0,

      totalCostAll: prevData.totalCostAll + costDifference,
    }))
    setErrors((prevErrors) => {
      const { outboundTerminalError, ...restErrors } = prevErrors
      return restErrors
    })
  }

  const handleInBoundTerminalChange = (e) => {
    const selectedId = e.target.value
    const selectedInBoundTerminal = inboundTerminals.find(
      (inboundTerminal) => inboundTerminal.terminalID === parseInt(selectedId)
    )
    const costDifference =
      selectedInBoundTerminal.terminalCost ||
      0 - bookingData.inBoundTerminalCost
    setBookingData((prevData) => ({
      ...prevData,
      inBoundTerminalName: selectedInBoundTerminal?.terminalName || '',
      inBoundTerminalId: selectedInBoundTerminal?.terminalID || '',
      inBoundTerminalCost: selectedInBoundTerminal?.terminalCost || 0,
      totalCostAll: prevData.totalCostAll + costDifference,
    }))
    setErrors((prevErrors) => {
      const { inboundTerminalError, ...restErrors } = prevErrors
      return restErrors
    })
  }

  const handleCarCleanService = (e) => {
    const selectedId = e.target.value
    const selectedCarCleanService = carCleanService.find(
      (service) => service.serviceID === parseInt(selectedId)
    )
    if (selectedCarCleanService) {
      const costDifference =
        selectedCarCleanService.servicePrice - (bookingData.carCleanCost || 0)

      setBookingData((prevData) => ({
        ...prevData,
        carCleanName: selectedCarCleanService.serviceName,
        carCleanId: selectedCarCleanService.serviceID,
        carCleanCost: selectedCarCleanService.servicePrice,
        totalCostAll: prevData.totalCostAll + costDifference,
      }))
    }
  }

  const handleElectricChargeService = (e) => {
    const selectedId = e.target.value
    const selectedElectricChargeService = electricChargeService.find(
      (service) => service.serviceID === parseInt(selectedId)
    )

    if (selectedElectricChargeService) {
      const costDifference =
        selectedElectricChargeService.servicePrice -
        (bookingData.electricChargeCost || 0)

      setBookingData((prevData) => ({
        ...prevData,
        electricChargeName: selectedElectricChargeService.serviceName,
        electricChargeId: selectedElectricChargeService.serviceID,
        electricChargeCost: selectedElectricChargeService.servicePrice,
        totalCostAll: prevData.totalCostAll + costDifference,
      }))
    }
  }
  const validateForm = () => {
    const newErrors = {}

    if (!bookingData.outBoundTerminalId) {
      newErrors.outboundTerminalError = 'Please select Outbound terminal'
    }
    if (!bookingData.inBoundTerminalId) {
      newErrors.inboundTerminalError = 'Please select inbound terminal'
    }

    setErrors(newErrors)

    return Object.keys(newErrors)?.length === 0
  }

  async function handleNext() {
    if (validateForm()) {
      setLoading(true)
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Booking/ProductOption`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // "ngrok-skip-browser-warning": "true",
            },
            body: JSON.stringify({
              quoteCode: bookingData.quoteCode,
              outboundTerminalID: bookingData.outBoundTerminalId,
              inboundTerminalID: bookingData.inBoundTerminalId,
              selectedCarCleanID: bookingData.carCleanId,
              selectedEChargeID: bookingData.electricChargeId,
              totalCost: bookingData.totalCostAll,
            }),
          }
        )
        if (!response.ok) {
          throw new Error('Failed to fetch quote')
        }
        const data = await response.json()
        setLoading(false)
        navigate('/booking_five')
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }
  }

  function handleBack() {
    navigate('/booking_three')
  }
  return (
    <div className="booking4-container-background">
      {loading && <BookingLoader />}

      <div className="booking-navbar-internal">
        <ul className="internal-navbar">
          <li>
            <NavLink to="/">Back to Home</NavLink>
          </li>
          <li className="nabar-item-1-booking-4">
            <a className="active1" to="#news">
              Get a Quote
            </a>
          </li>
          <li className="nabar-item-2-booking-4">
            <a href="#contact"> Available Products</a>
          </li>
          <li className="nabar-item-3-booking-4">
            <a href="#about">Products</a>
          </li>
          <li className="nabar-item-4-booking-4">
            <a href="#about">Booking Details</a>
          </li>
          <li className="nabar-item-5-booking-4">
            <a href="#about">Payment</a>
          </li>
          <li className="nabar-item-6-booking-4">
            <a href="#about">Payment Details</a>
          </li>
        </ul>
      </div>
      <div className="booking4-container">
        {/* <div className="status-bar4">
          <span className="status-item4">Status:</span>
          <span className="status-item4">Get a Quote</span>
          <span className="status-item4">Available Products</span>
          <span className="status-item4">Product Options</span>
          <span className="status-item4 status-active4">Booking Details</span>
        </div> */}
        <h2 className="booking4-header">Booking Details</h2>

        <div className="booking4-detail-item location-item4">
          <strong>Location:</strong>
          <span>Heathrow Airport</span>
        </div>
        <div className="booking4-detail-item">
          <strong>Site: </strong>
          <OpenNewWindow />
        </div>
        <div className="booking4-detail-item parking-item4">
          <strong>Parking Start:</strong>
          <span>{bookingData.parkingStart}</span>
        </div>
        <div className="booking4-detail-item parking-item4">
          <strong>Parking End:</strong>
          <span>{bookingData.parkingEnd}</span>
        </div>
        <div className="booking4-detail-item product-item4">
          <strong>Product:</strong>
          <span>LHR Flexible</span>
        </div>
        <div className="booking4-detail-item product-item4">
          <strong>Price:</strong>
          <span>{bookingData.totalDaysCost}</span>
        </div>
        <div className="booking4-detail-item terminal-item4">
          <strong>Outbound Terminal:</strong>
          <div className="terminal-options4">
            {bookingData.outboundTerminals?.map((terminal, index) => (
              <label key={index}>
                <input
                  type="radio"
                  name="outBoundTerminal"
                  onChange={handleOutBoundTerminalChange}
                  value={terminal.terminalID}
                  checked={
                    bookingData.outBoundTerminalId === terminal.terminalID
                  }
                />
                {terminal.terminalName}{' '}
                {terminal.terminalCost && '£' + terminal.terminalCost}
              </label>
            ))}
          </div>
          <div className="error-booking4">*</div>
          {errors.outboundTerminalError && (
            <p>{errors.outboundTerminalError}</p>
          )}
        </div>
        <div className="booking4-detail-item terminal-item4">
          <strong>Inbound Terminal:</strong>
          <div className="terminal-options4">
            {bookingData.inboundTerminals?.map((terminal, index) => (
              <label key={index}>
                <input
                  type="radio"
                  name="inBoundTerminal"
                  onChange={handleInBoundTerminalChange}
                  value={terminal.terminalID}
                  checked={
                    bookingData.inBoundTerminalId === terminal.terminalID
                  }
                />
                {terminal.terminalName}{' '}
                {terminal.terminalCost && '£' + terminal.terminalCost}
              </label>
            ))}
          </div>
          <div className="error-booking4">*</div>
          {errors.inboundTerminalError && <p>{errors.inboundTerminalError}</p>}
        </div>
        <div className="booking4-detail-item car-clean-item4">
          <strong>Car Clean:</strong>
          <div className="car-clean-options4">
            {bookingData.carCleanService?.map((service) => (
              <>
                <label key={service.serviceID}>
                  <input
                    type="radio"
                    name="carClean"
                    value={service.serviceID}
                    onChange={handleCarCleanService}
                    checked={bookingData.carCleanName === service.serviceName}
                  />
                  {service.serviceName}
                </label>
                <span>{service.serviceDescription}</span>
              </>
            ))}
          </div>
        </div>
        <div className="booking4-detail-item e-charge-item4">
          <strong>E-Charge:</strong>
          <div className="e-charge-options4">
            {bookingData.electricChargeService?.map((service) => (
              <label key={service.serviceID}>
                <input
                  type="radio"
                  name="electricCharge"
                  value={service.serviceID}
                  onChange={handleElectricChargeService}
                  checked={
                    bookingData.electricChargeName === service.serviceName
                  }
                />
                {service.serviceName}
              </label>
            ))}
          </div>
        </div>
        <div className="booking4-detail-item total-cost-item4">
          <strong>Total Cost:</strong>
          <div className="calculator-values">
            <div className="single-value">
              <span>LHR Flexible</span>
              <span>£{bookingData.totalDaysCost}</span>
            </div>
            <div className="single-value">
              <span>
                {bookingData.outBoundTerminalName &&
                bookingData.outBoundTerminalCost
                  ? `Outbound Terminal: ${bookingData.outBoundTerminalName}`
                  : ''}
              </span>
              <span>
                {bookingData.outBoundTerminalName &&
                bookingData.outBoundTerminalCost
                  ? `£${bookingData.outBoundTerminalCost}`
                  : ''}
              </span>
            </div>
            <div className="single-value">
              <span>
                {bookingData.inBoundTerminalName &&
                bookingData.inBoundTerminalCost
                  ? `Inbound Terminal: ${bookingData.inBoundTerminalName}`
                  : ''}
              </span>
              <span>
                {bookingData.inBoundTerminalName &&
                bookingData.inBoundTerminalCost
                  ? `£${bookingData.inBoundTerminalCost}`
                  : ''}
              </span>
            </div>
            <div className="single-value">
              <span>
                {bookingData.carCleanName && bookingData.carCleanCost
                  ? bookingData.carCleanName
                  : ''}
              </span>
              <span>
                {bookingData.carCleanName && bookingData.carCleanCost
                  ? `£${bookingData.carCleanCost}`
                  : ''}
              </span>
            </div>
            <div className="single-value">
              <span>
                {bookingData.electricChargeName &&
                bookingData.electricChargeCost
                  ? bookingData.electricChargeName
                  : ''}
              </span>
              <span>
                {bookingData.electricChargeName &&
                bookingData.electricChargeCost
                  ? `£${bookingData.electricChargeCost}`
                  : ''}
              </span>
            </div>
            <div className="total-value">
              <span></span>
              <span>£{bookingData.totalCostAll}</span>
            </div>
          </div>
        </div>
        <div className="actions4">
          <button type="button" onClick={handleBack}>
            Previous
          </button>
          <button type="button" onClick={handleNext}>
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

export default Booking4
