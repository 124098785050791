import 'rsuite/dist/rsuite.min.css'
import './Booking1.css'
import React, { useEffect, useState } from 'react'
import { useBooking } from './../BookingContext/DataContext'
import { useNavigate } from 'react-router-dom'
import { API_BASE_URL } from '../../Config/Config'

export default function Booking1() {
  const { bookingData, setBookingData } = useBooking()
  const navigate = useNavigate()
  const [airports, setAirports] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(() => {
    const fetchAirports = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Airport/GetAllAirportDetails`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()

        setAirports(data)
        setBookingData((prevData) => ({
          ...prevData,
          airportState: true,
          airports: data,
        }))
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchAirports()
  }, [])

  // Default parking start and end dates
  useEffect(() => {
    if (!bookingData.parkingStart || !bookingData.parkingEnd) {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(today.getDate() + 1)
      const dayAfterTomorrow = new Date(today)
      dayAfterTomorrow.setDate(today.getDate() + 2)

      setBookingData((prevData) => ({
        ...prevData,
        parkingStart: tomorrow.toISOString().slice(0, 16),
        parkingEnd: dayAfterTomorrow.toISOString().slice(0, 16),
        totalCostAll: bookingData.totalCostAll || 0,
      }))
    }
  }, [bookingData, setBookingData])

  const validateForm = () => {
    const newErrors = {}
    const { parkingStart, parkingEnd, airportName } = bookingData

    if (!airportName) {
      newErrors.airportName = 'Please select a location.'
    }

    if (!parkingStart) {
      newErrors.parkingStart = 'Please select a parking start time.'
    }

    if (!parkingEnd) {
      newErrors.parkingEnd = 'Please select a parking end time.'
    }

    const startDate = new Date(parkingStart)
    const endDate = new Date(parkingEnd)

    if (startDate < new Date()) {
      newErrors.parkingStart = 'Parking start time cannot be in the past.'
    } else {
      // newErrors.parkingStart = "";
      // setErrors("");
    }

    if (startDate.getHours() < 4 || endDate.getHours() < 4) {
      newErrors.timeRestriction =
        'Parking times cannot be between 12 AM and 4 AM.'
    } else {
      // newErrors.timeRestriction = "";
    }

    if (endDate <= startDate) {
      newErrors.parkingEnd = 'Parking end time must be later than start time.'
    } else if (endDate - startDate < 3600000) {
      newErrors.parkingEnd =
        'Parking end time must be at least 1 hour after start time.'
    } else {
      // newErrors.parkingEnd = "";
    }

    setErrors(newErrors)

    return Object.keys(newErrors)?.length === 0
  }

  const handleOnAirportChange = (e) => {
    const selectedId = e.target.value
    const selectedAirport = airports.find(
      (airport) => airport.airportID === parseInt(selectedId, 10)
    )

    setBookingData((prevData) => ({
      ...prevData,
      airportId: selectedAirport?.airportID || '',
      airportName: selectedAirport?.airportName || '',
      airportCode: selectedAirport?.airportZipCode || '',
      totalCostAll: prevData.totalCostAll || 0,

      outboundTerminals: [],
      inboundTerminals: [],
      terminalState: false,

      carCleanService: [],
      electricChargeService: [],
      serviceState: false,

      outBoundTerminalName: '',
      outBoundTerminalId: '',
      outBoundTerminalCost: 0,

      inBoundTerminalName: '',
      inBoundTerminalId: '',
      inBoundTerminalCost: 0,

      carCleanName: 'None',
      carCleanId: null,
      carCleanCost: 0,

      electricChargeName: 'None',
      electricChargeId: null,
      electricChargeCost: 0,
    }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setBookingData((prevData) => ({
      ...prevData,
      [name]: value,
      totalCostAll: bookingData.totalCostAll || 0,
    }))
  }

  // useEffect(() => {
  //   setBookingData((prevData) => ({
  //     ...prevData,
  //     totalDays: '',
  //     totalDaysCost: '',
  //   }))
  // }, [bookingData.totalDays])

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateForm()) {
      const startDate = new Date(bookingData.parkingStart)
      const endDate = new Date(bookingData.parkingEnd)
      const differenceInDays = Math.ceil(
        (endDate - startDate) / (1000 * 60 * 60 * 24)
      )

      let cost = 78.9
      if (differenceInDays === 2) {
        cost += 1
      } else if (differenceInDays > 2) {
        cost += 7 * (differenceInDays - 2)
      }
      const costDifferenceForDays = cost - (bookingData.totalDaysCost || 0)
      const costDifferenceForAll = cost - (bookingData.totalCostAll || 0)

      setBookingData((prevData) => ({
        ...prevData,
        totalDays: differenceInDays,
        totalDaysCost: prevData.totalDaysCost + costDifferenceForDays,
        totalCostAll: prevData.totalCostAll + costDifferenceForAll,
      }))
      navigate('/booking_two')
    }
  }
  // console.log(bookingData.totalDays)
  // console.log(bookingData.totalDaysCost)
  // console.log(bookingData.totalCostAll)
  return (
    <div
      className="container-fluid d-flex justify-content-center align-items-center flex-column main-box booking-bg"
      id="booking"
    >
      <div className="container-fluid Services mx-2 my-2 slider-placement justify-content-center d-flex my-3">
        <div className="slider-box text-center">
          <h2 className="topheading">
            <b>Book your parking</b>
          </h2>
          <br />
          <p>"Your Perfect Parking Spot, Just a Click Away!"</p>
        </div>
      </div>
      <form className="row form-main-box" onSubmit={handleSubmit}>
        <div className="topleftbox"></div>
        <div className="bottomrightbox"></div>
        <div className="d-flex flex-column justify-content-center rounded-3 form-small-box">
          <label htmlFor="Location">
            <b>Location</b>
          </label>
          <select
            className="rounded px-2"
            id="airportSelect"
            onChange={handleOnAirportChange}
            value={bookingData.airportId || ''}
          >
            <option value="" disabled selected>
              Select an airport
            </option>
            {bookingData.airports?.map((airport) => (
              <option key={airport.airportID} value={airport.airportID}>
                {airport.airportName}
              </option>
            ))}
          </select>
          {errors.airportName && (
            <small className="error-booking1">{errors.airportName}</small>
          )}
          <div className="datesbox">
            <label htmlFor="parkingStart">
              <b>Parking Start</b>
            </label>
            <input
              name="parkingStart"
              type="datetime-local"
              className="rounded input"
              value={bookingData.parkingStart}
              onChange={handleChange}
              min={new Date().toISOString().slice(0, 16)}
            />
            {errors.parkingStart && (
              <p className="error-booking1">{errors.parkingStart}</p>
            )}
            <label htmlFor="parkingEnd">
              <b>Parking End</b>
            </label>
            <input
              name="parkingEnd"
              type="datetime-local"
              className="rounded input"
              value={bookingData.parkingEnd}
              onChange={handleChange}
              min={new Date().toISOString().slice(0, 16)}
            />
            {errors.parkingEnd && (
              <small className="error-booking1 ">{errors.parkingEnd}</small>
            )}
          </div>
          {errors.timeRestriction && (
            <small className="error">{errors.timeRestriction}</small>
          )}
          <div className="discount-box">
            <label htmlFor="discountCode">
              <b>Discount Code</b>
            </label>
            <input
              type="text"
              name="discountCode"
              placeholder="Enter your discount code"
              value={bookingData.discountCode}
              onChange={handleChange}
            />
          </div>
          <div className="button-boxQuote">
            <button type="submit" className="btn my-4">
              Book Now
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
