import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import './Team.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import linkedin from '../../assets/linkedin.png'
import facebook from '../../assets/facebook.png'
import instagram from '../../assets/instagram.png'
import gmail from '../../assets/gmail.png'
import twitter from '../../assets/twitter.png'
import { API_BASE_URL } from '../../Config/Config'

export default function Team() {
  const sliderRef = useRef(null)
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [slidesToShow, setSlidesToShow] = useState(3)

  useEffect(() => {
    fetch(`${API_BASE_URL}/api/Team/AllMembers`, {
      method: 'GET',
      headers: {
        // 'ngrok-skip-browser-warning': 'true',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      })
      .then((data) => {
        setData(data)
        setLoading(false)
      })
      .catch((error) => {
        setError(error)
        setLoading(false)
      })

    const handleResize = () => {
      const width = window.innerWidth
      if (width < 600) {
        setSlidesToShow(1)
      } else if (width < 1024) {
        setSlidesToShow(2)
      } else {
        setSlidesToShow(3)
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Initial call to set the slides based on current window size

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    dots: true,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  }

  if (loading) return <div>Loading...</div>
  if (error) return <div>Error: {error.message}</div>

  return (
    <div className="container main-box" id="about">
      <h1 className="team-title">Our Team</h1>
      <p className="team-desc">
        At ASKAII, our dedicated team is committed to delivering exceptional
        service and ensuring a seamless experience for all our customers. With a
        focus on reliability, efficiency, and customer satisfaction, our
        professionals work tirelessly to manage every aspect of your parking and
        travel needs. From secure parking solutions and comprehensive vehicle
        services to convenient airport amenities and transfers, our team is here
        to provide a stress-free start to your journey. Trust us to handle the
        details so you can focus on enjoying your trip.
      </p>
      <Slider ref={sliderRef} {...settings} className="team-slider">
        {data.map((item, index) => (
          <div key={index} className="slide-box">
            <div className="teams-card main-card">
              <div
                className="card-front"
                style={{
                  backgroundImage: `url(${API_BASE_URL}${item.profilePicturePath})`,
                }}
              >
                <div className="card-name">
                  <h1>{item.name}</h1>
                  <h4>{item.designation}</h4>
                </div>
              </div>
              <div className="card-back ">
                <img
                  className="profile-img"
                  src={`${API_BASE_URL}${item.profilePicturePath}`}
                  alt="Chaudary Mohsin"
                />
                <h2>{item.name}</h2>
                <h4>{item.designation}</h4>
                <p>{item.details}</p>
                <div className="social-icons">
                  <a href={item.linkedIn} target="_blank" rel="noreferrer">
                    <img src={linkedin} alt="LinkedIn" />
                  </a>
                  <a href={item.facebook} target="_blank" rel="noreferrer">
                    <img src={facebook} alt="Facebook" />
                  </a>
                  <a href={item.instagram} target="_blank" rel="noreferrer">
                    <img src={instagram} alt="Instagram" />
                  </a>
                  <a href={item.email} target="_blank" rel="noreferrer">
                    <img src={gmail} alt="Gmail" />
                  </a>
                  <a href={item.twitter} target="_blank" rel="noreferrer">
                    <img src={twitter} alt="Twitter" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
