import React, { useEffect, useState } from 'react'
import './Booking5.css'
import OpenNewWindow from './ExampleComp/NewWindow'
import { useNavigate } from 'react-router-dom'
import { useBooking } from '../BookingContext/DataContext'
import { API_BASE_URL } from '../../Config/Config'
import { NavLink } from 'react-router-dom'
import BookingLoader from '../BookingLoader/LoadingAndError'

const Booking5 = () => {
  const { bookingData, setBookingData } = useBooking()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [errors, setErrors] = useState({})

  const navigate = useNavigate()

  const [allMake, setAllMake] = useState([])
  const [allModel, setAllModel] = useState([])
  const [allColour, setAllColour] = useState([])

  useEffect(() => {
    const allVehicles = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Vehicle/allVehicles`,
          {
            headers: {
              'ngrok-skip-browser-warning': 'true',
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        setAllMake(data)
        setBookingData((prevData) => ({
          ...prevData,
          allMake: data,
        }))
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    allVehicles()
  }, [])
  useEffect(() => {
    const GetAllColors = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Vehicle/GetAllColors`,
          {
            headers: {
              'ngrok-skip-browser-warning': 'true',
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        setAllColour(data)
        setBookingData((prevData) => ({
          ...prevData,
          allColour: data,
        }))
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    GetAllColors()
  }, [])

  const handleMakeChange = (e) => {
    const selectedId = e.target.value

    if (!(selectedId == 0)) {
      const selecteMake = allMake.find(
        (make) => make.vehicleMakeID === parseInt(selectedId)
      )
      setAllModel(selecteMake?.vehicleModels)

      setBookingData((prevData) => ({
        ...prevData,
        carMakeName: selecteMake?.vehicleMakeName || '',
        carMakeId: selecteMake?.vehicleMakeID || 0,
        allModel: selecteMake?.vehicleModels,
        // carModelName: bookingData.carModelName || "",
        // carModelId: bookingData.carModelId || 0,
      }))
      // Remove the inbound terminal error if any
      setErrors((prevErrors) => {
        const { carOtherMakeNameError, ...restErrors } = prevErrors
        return restErrors
      })
    }
    if (selectedId == 0) {
      setBookingData((prevData) => ({
        ...prevData,
        carMakeName: 'Other',
        carMakeId: 0,
        // allModel: "",
        // carModelName: bookingData.carModelName || "",
        // carModelId: bookingData.carModelId || 0,
      }))
    }
  }

  const handleModelChange = (e) => {
    const selectedId = e.target.value
    const selectedModel = bookingData.allModel?.find(
      (make) => make.vehicleModelID === parseInt(selectedId)
    )
    setBookingData((prevData) => ({
      ...prevData,
      carModelName: selectedModel?.vehicleModelName || '',
      carModelId: selectedModel?.vehicleModelID || 0,
    }))
  }

  const handleColourChange = (e) => {
    const selectedId = e.target.value
    const selectedColour = allColour?.find(
      (colour) => colour.vehicleColorID === parseInt(selectedId)
    )
    setBookingData((prevData) => ({
      ...prevData,
      carColourName: selectedColour?.vehicleColorName || '',
      carColourId: selectedColour?.vehicleColorID || 0,
    }))
  }

  const handleBack = () => {
    navigate('/booking_four')
  }

  const handleRegistrationChange = (e) => {
    const { name, type, value, checked } = e.target
    setBookingData((prevData) => ({
      ...prevData,
      [name]:
        type === 'checkbox'
          ? checked
          : value === 'true'
          ? true
          : value === 'false'
          ? false
          : value,
    }))

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors }
      const errorFieldName = `${name}Error`

      // Remove error for the changed field if it exists
      if (newErrors[errorFieldName]) {
        delete newErrors[errorFieldName]
      }

      // Additional logic to handle specific toggles
      if (name === 'carMake' && value !== 'Other') {
        // If carMake is not "Other", remove the error for carOtherMakeName
        delete newErrors.carOtherMakeNameError
      }
      if (name === 'differentFromAirport' && !checked) {
        // If differentFromAirport is unchecked, remove the error for flyingFrom
        delete newErrors.flyingFromError
      }

      return newErrors
    })
  }
  const validateForm = () => {
    const newErrors = {}

    if (!bookingData.userSurName) {
      newErrors.userSurNameError = 'Please enter your surname'
    }
    if (!bookingData.carRegistrationNo) {
      newErrors.carRegistrationNoError =
        'Please enter your car registration NO.'
    }
    if (!bookingData.carMakeId && !bookingData.carOtherMakeName) {
      newErrors.carOtherMakeNameError = 'Please select your car make'
    } else if (bookingData.carMakeId === '0' && !bookingData.carOtherMakeName) {
      newErrors.carOtherMakeNameError = 'Please enter your car make'
    }
    // if (!bookingData.carModelId) {
    //   newErrors.carModelIdError = "Please select your car model";
    // }
    if (!bookingData.flyingTo) {
      newErrors.flyingToError = 'Please enter your flying to details'
    }
    if (bookingData.isDifferentFromAirport && !bookingData.flyingFrom) {
      newErrors.flyingFromError = 'Please enter your flying from details'
    } else if ('') {
      return
    }
    if (!bookingData.inBoundFlightNo) {
      newErrors.inBoundFlightNoError = 'Please enter your inbound flight number'
    }
    if (!bookingData.isAgreeOnTerms) {
      newErrors.isAgreeOnTermsError = 'Please select agree on terms'
    }

    setErrors(newErrors)

    // Form is valid if there are no errors
    return Object.keys(newErrors)?.length === 0
  }

  const handleNext = async (e) => {
    e.preventDefault()
    if (validateForm()) {
      setLoading(true)
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Booking/BookingDetail`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // "ngrok-skip-browser-warning": "true",
            },
            body: JSON.stringify({
              customerFirstName: 'fkjdlskaj',
              quoteCode: bookingData.quoteCode,
              customerLastName: bookingData.userSurName,
              customerEmail: 'jgkdiqh@gmail.com',
              phoneNumber: bookingData.userMobileNo,
              customerTitle: bookingData.userTitle,
              customerDescription: 'string',
              companyName: bookingData.userCompanyName,
              vehicleName: bookingData.carMakeName,
              registrationNumber: bookingData.carRegistrationNo,
              vehicleVersion: 'string',
              vehicleMakeID: bookingData.carMakeId,
              vehcileModelID: bookingData.carModelId,
              vehicleColorID: bookingData.carColourId,
              otherMake: bookingData.otherMake,
              otherModel: bookingData.otherModel,
              otherColor: bookingData.otherColor,
              flyingTo: bookingData.flyingTo,
              flyingFrom: bookingData.flyingFrom,
              inboundFlightNo: bookingData.inBoundFlightNo,
              outboundFlightNo: bookingData.outBoundFlightNo,
              isRegister: false,
              isPromotions: true,
              isFlyingfromDifferent: true,
            }),
          }
        )
        if (!response.ok) {
          throw new Error('Failed to fetch quote')
        }
        const data = await response.json()
        setLoading(false)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }

      navigate('/booking_six')
    }
  }
  return (
    <div className="booking5-container-background">
      {loading && <BookingLoader />}

      <div className="booking-navbar-internal">
        <ul className="internal-navbar">
          <li>
            <NavLink to="/">Back to Home</NavLink>
          </li>
          <li className="nabar-item-1-booking-5">
            <a className="active1" to="#news">
              Get a Quote
            </a>
          </li>
          <li className="nabar-item-2-booking-5">
            <a href="#contact"> Available Products</a>
          </li>
          <li className="nabar-item-3-booking-5">
            <a href="#about">Products</a>
          </li>
          <li className="nabar-item-4-booking-5">
            <a href="#about">Booking Details</a>
          </li>
          <li className="nabar-item-5-booking-5">
            <a href="#about">Payment</a>
          </li>
          <li className="nabar-item-6-booking-5">
            <a href="#about">Payment Details</a>
          </li>
        </ul>
      </div>
      <div className="booking5-container">
        <h2 className="booking5-header">Booking Details</h2>
        <form>
          <div className="booking5-detail-item location-item5">
            <strong>Location:</strong>
            <span>{bookingData.airportName}</span>
          </div>
          <div className="booking5-detail-item">
            <strong>Site:</strong>
            <OpenNewWindow />
          </div>
          <div className="booking5-detail-item parking-item5">
            <strong>Parking Start:</strong>
            <span>{bookingData.parkingStart}</span>
          </div>
          <div className="booking5-detail-item parking-item5">
            <strong>Parking End:</strong>
            <span>{bookingData.parkingEnd}</span>
          </div>
          <div className="booking5-detail-item product-item5">
            <strong>Product:</strong>
            <span>LHR Flexible</span>
          </div>
          <div className="booking5-detail-item outbound-item5">
            <strong>Outbound Terminal:</strong>
            <span>{bookingData.outBoundTerminalName}</span>
          </div>
          <div className="booking5-detail-item intbound-item5">
            <strong>Inbound Terminal:</strong>
            <span>{bookingData.inBoundTerminalName}</span>
          </div>
          <div className="booking5-detail-item total-cost-item5">
            <strong>Total Cost:</strong>
            <div className="calculator-values">
              <div className="single-value">
                <span>LHR Flexible</span>
                <span>£{bookingData.totalDaysCost}</span>
              </div>
              <div className="single-value">
                <span>
                  {bookingData.outBoundTerminalName &&
                  bookingData.outBoundTerminalCost
                    ? `Outbound Terminal. ${bookingData.outBoundTerminalName}`
                    : ''}
                </span>
                <span>
                  {bookingData.outBoundTerminalName &&
                  bookingData.outBoundTerminalCost
                    ? `£${bookingData.outBoundTerminalCost}`
                    : ''}
                </span>
              </div>
              <div className="single-value">
                <span>
                  {bookingData.inBoundTerminalName &&
                  bookingData.inBoundTerminalCost
                    ? `Inbound Terminal: ${bookingData.inBoundTerminalName}`
                    : ''}
                </span>
                <span>
                  {bookingData.inBoundTerminalName &&
                  bookingData.inBoundTerminalCost
                    ? `£${bookingData.inBoundTerminalCost}`
                    : ''}
                </span>
              </div>
              <div className="single-value">
                <span>
                  {bookingData.carCleanName && bookingData.carCleanCost
                    ? bookingData.carCleanName
                    : ''}
                </span>
                <span>
                  {bookingData.carCleanName && bookingData.carCleanCost
                    ? `£${bookingData.carCleanCost}`
                    : ''}
                </span>
              </div>
              <div className="single-value">
                <span>
                  {bookingData.electricChargeName &&
                  bookingData.electricChargeCost
                    ? bookingData.electricChargeName
                    : ''}
                </span>
                <span>
                  {bookingData.electricChargeName &&
                  bookingData.electricChargeCost
                    ? `£${bookingData.electricChargeCost}`
                    : ''}
                </span>
              </div>
              <div className="total-value">
                <span></span>
                <span>£{bookingData.totalCostAll}</span>
              </div>
            </div>
          </div>

          <h2 className="booking5-header">Personal Details</h2>

          <div className="registration-section5">
            <div className="booking5-detail-item">
              <strong>Email:</strong>
              <p>{bookingData.email}</p>
            </div>
            {/* <div className="booking5-detail-item">
            <strong>Register:</strong>
            <div className="register-check-box">
              <input
                type="checkbox"
                name="userIsRegister"
                onChange={handleRegistrationChange}
                checked={bookingData.userIsRegister || false}
              />
              <p>Tick to create an Account</p>
            </div>
          </div> */}
            <div className="booking5-detail-item-promotion">
              <strong>Promotions:</strong>
              <div>
                <label>
                  <input
                    type="radio"
                    name="promotions"
                    value="false"
                    onChange={handleRegistrationChange}
                    checked={bookingData.promotions === false}
                  />{' '}
                  No
                </label>
                <label>
                  <input
                    type="radio"
                    name="promotions"
                    value="true"
                    onChange={handleRegistrationChange}
                    checked={bookingData.promotions === true}
                  />{' '}
                  Yes
                </label>
                <label>
                  Tick to create an account, saving time on your next booking.
                </label>
              </div>
            </div>
            <div className="booking5-detail-item">
              <strong>Title:</strong>
              <select
                name="userTitle"
                onChange={handleRegistrationChange}
                value={bookingData.userTitle}
              >
                <option value="" disabled>
                  Select an Option
                </option>
                <option value="Other">Other</option>
                <option value="Mr. ">Mr</option>
                <option value="Mrs. ">Mrs</option>
                <option value="Ms. ">Ms</option>
              </select>
            </div>
            <div className="booking5-detail-item">
              <strong>First Name:</strong>
              <input
                type="text"
                name="userFirstName"
                value={bookingData.userFirstName}
                onChange={handleRegistrationChange}
              />
            </div>
            <div className="booking5-detail-item">
              <strong>Surname:</strong>
              <input
                type="text"
                name="userSurName"
                value={bookingData.userSurName}
                onChange={handleRegistrationChange}
                required
              />
              <div className="error-booking5">*</div>
              {errors.userSurNameError && (
                <p className="booking5-error-message">
                  {errors.userSurNameError}
                </p>
              )}
            </div>

            <div className="booking5-detail-item">
              <strong>Company Name:</strong>
              <input
                type="text"
                name="userCompanyName"
                value={bookingData.userCompanyName}
                onChange={handleRegistrationChange}
              />
            </div>
            <div className="booking5-detail-item">
              <strong>Phone:</strong>
              <input
                type="text"
                name="userMobileNo"
                value={bookingData.userMobileNo}
                onChange={handleRegistrationChange}
              />
            </div>
            <div className="booking5-detail-item">
              <strong>Source: </strong>
              <div className="booking5-detail-item-text">
                <select
                  name="userSource"
                  onChange={handleRegistrationChange}
                  value={bookingData.userSource}
                >
                  <option value="website">Website</option>
                  <option value="socialMedia">Social Media</option>
                  <option value="friendsAndFamily">Friend & Family</option>
                  <option value="other">Other</option>
                </select>
                <label>How did you hear about us?</label>
              </div>
            </div>

            {bookingData.userSource === 'other' ? (
              <div className="booking5-detail-item">
                <strong>Other Source:</strong>
                <input
                  type="text"
                  name="otherUserSource"
                  onChange={handleRegistrationChange}
                  value={bookingData.otherUserSource}
                />
              </div>
            ) : (
              ''
            )}
          </div>

          <h2 className="booking5-header">Vehicle Details</h2>
          <div className="vehicle-section5">
            <div className="booking5-detail-item">
              <strong>Registration No:</strong>
              <div className="booking5-detail-item-text">
                <div className="input-wrapper">
                  <input
                    className="booking5-input"
                    type="text"
                    name="carRegistrationNo"
                    value={bookingData.carRegistrationNo}
                    onChange={handleRegistrationChange}
                  />
                  <span className="asterisk">*</span>
                  {errors.carRegistrationNoError && (
                    <span className="booking5-error-message">
                      {errors.carRegistrationNoError}
                    </span>
                  )}
                </div>

                <label>e.g. AB51ABC</label>
                <label>
                  Your registration is used to recognise your car on entry to
                  the car park.
                </label>
              </div>
              {/* <div className="error-booking5">*</div> */}
            </div>

            <div className="booking5-detail-item">
              <strong>Make:</strong>
              <select
                onChange={handleMakeChange}
                value={bookingData.carMakeId || ''}
              >
                <option value="0">Other</option>
                {bookingData.allMake.map((make) => (
                  <>
                    <option key={make.vehicleMakeID} value={make.vehicleMakeID}>
                      {make.vehicleMakeName}
                    </option>
                  </>
                ))}
              </select>
            </div>
            {bookingData.carMakeId ? (
              ''
            ) : (
              <div className="booking5-detail-item">
                <strong>Other Make:</strong>
                <div className="booking5-detail-item-text">
                  <div className="input-wrapper">
                    <input
                      className="booking5-input"
                      type="text"
                      name="carOtherMakeName"
                      onChange={handleRegistrationChange}
                      value={bookingData.carOtherMakeName}
                    />
                    <span className="asterisk">*</span>
                    {errors.carOtherMakeNameError && (
                      <span className="booking5-error-message">
                        {errors.carOtherMakeNameError}
                      </span>
                    )}
                  </div>
                  <label>
                    If your vehicle make is not shown, choose [other] and enter
                    below instead.
                  </label>
                  <label>Add * at the beginning to match within text.</label>
                </div>
              </div>
            )}

            <div className="booking5-detail-item">
              <strong>Model:</strong>
              <select
                className="booking5-input"
                onChange={handleModelChange}
                value={bookingData.carModelId || ''}
              >
                <option value="">Other</option>
                {bookingData.allModel?.map((model) => (
                  <option
                    key={model.vehicleModelID}
                    value={model.vehicleModelID}
                  >
                    {model?.vehicleModelName}
                  </option>
                ))}
              </select>
            </div>
            {bookingData.carModelId ? (
              ''
            ) : (
              <div className="booking5-detail-item">
                <strong>Other Model:</strong>
                <input
                  type="text"
                  name="carOtherModelName"
                  onChange={handleRegistrationChange}
                  value={bookingData.carOtherModelName}
                />
              </div>
            )}
            <div className="booking5-detail-item">
              <strong>Colour:</strong>
              <div className="booking5-detail-item-text">
                <select
                  className="booking5-input"
                  onChange={handleColourChange}
                  value={bookingData.carColourId || ''}
                >
                  <option>Other</option>
                  {bookingData.allColour?.map((colour) => (
                    <option
                      key={colour.vehicleColorID}
                      value={colour.vehicleColorID}
                    >
                      {colour?.vehicleColorName}
                    </option>
                  ))}
                </select>
                <label>
                  If your vehicle colour is not shown, choose [other] and enter
                  below instead
                </label>
              </div>
            </div>
            {bookingData.carColourId ? (
              ''
            ) : (
              <div className="booking5-detail-item">
                <strong>Other Colour:</strong>
                <input
                  type="text"
                  name="carOtherColourName"
                  onChange={handleRegistrationChange}
                  value={bookingData.carOtherColourName}
                />
              </div>
            )}
          </div>

          <h2 className="booking5-header">Flight Details</h2>
          <div className="flight-section5">
            <div className="booking5-detail-item-details">
              <strong>Flying To:</strong>
              <div className="booking5-detail-item-text">
                <input
                  className="booking5-input"
                  type="text"
                  name="flyingTo"
                  onChange={handleRegistrationChange}
                  value={bookingData.flyingTo}
                />

                <label>Enter the destination Airport</label>
              </div>
              <div className="error-booking5">*</div>
              {errors.flyingToError && (
                <span className="booking5-error-message">
                  {errors.flyingToError}
                </span>
              )}
            </div>
            <div className="booking5-detail-item-details">
              <strong>Outbound Flight No:</strong>
              <div className="booking5-detail-item-text">
                <input
                  className="booking5-input"
                  type="text"
                  name="outBoundFlightNo"
                  onChange={handleRegistrationChange}
                  value={bookingData.outBoundFlightNo}
                />
                <label>Enter your departing flight number</label>
                <label>e.g. ABC123</label>
              </div>
            </div>
            <div className="booking5-detail-item-details">
              <strong>Different From Airport:</strong>
              <div className="booking5-detail-item-text">
                <div className="checkbox-colman">
                  <input
                    type="checkbox"
                    name="isDifferentFromAirport"
                    onChange={handleRegistrationChange}
                    value={bookingData.isDifferentFromAirport}
                    checked={bookingData.isDifferentFromAirport === true}
                  />
                  <label>
                    Tick if you are flying back from a different Airport
                  </label>
                </div>
              </div>
            </div>
            {bookingData.isDifferentFromAirport === true ? (
              <div className="booking5-detail-item-details">
                <strong>Flying From:</strong>
                <div className="booking5-detail-item-text">
                  <div className="input-wrapper">
                    <input
                      className="booking5-input"
                      type="text"
                      name="flyingFrom"
                      onChange={handleRegistrationChange}
                      value={bookingData.flyingFrom}
                    />
                    <span className="asterisk">*</span>
                    {errors.flyingFromError && (
                      <span className="booking5-error-message">
                        {errors.flyingFromError}
                      </span>
                    )}
                  </div>
                  <label>Enter your return flight number</label>
                  <label>e.g. ABC123</label>
                </div>
              </div>
            ) : (
              ''
            )}

            <div className="booking5-detail-item-details">
              <strong>Inbound Flight No:</strong>
              <input
                className="booking5-input"
                type="text"
                name="inBoundFlightNo"
                onChange={handleRegistrationChange}
                value={bookingData.inBoundFlightNo}
              />
              <div className="error-booking5">*</div>
              {errors.inBoundFlightNoError && (
                <span className="booking5-error-message">
                  {errors.inBoundFlightNoError}
                </span>
              )}
            </div>
          </div>

          <h2 className="booking5-header">Terms & Conditions</h2>
          <div className="terms-section5">
            <div className="booking5-detail-item-details">
              <strong>Agree Terms:</strong>
              <div className="booking5-last-input-alingnment">
                <div className="input-wrapper">
                  <input
                    type="checkbox"
                    name="isAgreeOnTerms"
                    onChange={handleRegistrationChange}
                    value={bookingData.isAgreeOnTerms}
                    checked={bookingData.isAgreeOnTerms === true}
                  />

                  <span className="asterisk">*</span>
                  {errors.isAgreeOnTermsError && (
                    <span className="booking5-error-message">
                      {errors.isAgreeOnTermsError}
                    </span>
                  )}
                </div>
                <label>
                  Please tick to confirm you have read and agree to the ASKAII
                  Parking terms and conditions.
                </label>
              </div>
            </div>
          </div>

          <div className="actions5">
            <button type="button" onClick={handleBack}>
              Previous
            </button>
            <button type="button" onClick={handleNext}>
              Continue
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Booking5
