import React from "react";
import "./NewWindow.css";

const OpenNewWindow = () => {
  const width = window.screen.width;
  const height = window.screen.height;
  const handleClick = () => {
    const newWindow = window.open(
      "/about-booking",
      "_blank",
      `noopener,noreferrer,width=${width},height=${height}`
    );

    if (newWindow) {
      newWindow.focus();
    }
  };

  return (
    <div className="new-window-main">
      {/* <div className="main-link" onClick={handleClick}>
        ASKAII Parking
      </div> */}
      <div className="main-text">
        <span className="main-link" onClick={handleClick}>
          ASKAII Parking{" "}
        </span>
        For more information click on the site name above. (Opens in a new
        window)
      </div>
    </div>
  );
};

export default OpenNewWindow;
