import React from 'react'
import './ForgetPassword.css'

function ForgetPassword() {
  return (
    <>
      <form>
        <div className="forget-container">
          <div className="forget-form">
            <h2>Forget Password</h2>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Expedita, nulla ducimus! Nisi dolorum error beatae voluptas
              provident veniam ipsam totam. Sed ratione ut, alias sunt
              voluptatum cum blanditiis dolor veniam dolorem facilis rerum
              dignissimos perferendis! Possimus animi rerum quod asperiores,
              amet autem sed, aut sint nostrum, voluptates maxime odit
              blanditiis?
            </p>
            <br />
            <br />

            <label for="exampleInputEmail1" className="form-label">
              <b>Enter Your E-mail:</b>
              <input
                type="email"
                className="form-control forget-form-new"
                // value={password}

                placeholder="Enter your E-mail"
                required
              />
            </label>

            <button type="submit" className="btn btn-primary">
              Send OTP
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default ForgetPassword
