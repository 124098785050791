import React from 'react'
import './ConfirmPassword.css'

function ConfermPassword() {
  return (
    <>
      <form>
        <div className="confirm-container">
          <div className="confirm-form">
            <h2>Confirm password</h2>
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Expedita, nulla ducimus! Nisi dolorum error beatae voluptas
              provident veniam ipsam totam. Sed ratione ut, alias sunt
              voluptatum cum blanditiis dolor veniam dolorem facilis rerum
              dignissimos perferendis! Possimus animi rerum quod asperiores,
              amet autem sed, aut sint nostrum, voluptates maxime odit
              blanditiis?
            </p>
            <br />
            <br />

            <label for="exampleInputEmail1" className="form-label">
              <b>Enter Your OTP:</b>
              <input
                type="text"
                className="form-control confirm-form-new"
                // value={password}

                placeholder="Enter your OTP"
                required
              />
            </label>

            <button type="submit" className="btn btn-primary">
              Confirm
            </button>
          </div>
        </div>
      </form>
    </>
  )
}

export default ConfermPassword
