import React, { useState, useEffect } from 'react'
import Icon from './../../assets/icon_1.png'
import { useNavigate } from 'react-router-dom'
import { useBooking } from '../BookingContext/DataContext'
import { API_BASE_URL } from '../../Config/Config'
import right_image from './../../assets/form-bg.png'
import './home.css'

export default function HeroSection() {
  const [showForm, setShowForm] = useState(false)
  const { bookingData, setBookingData } = useBooking()
  const navigate = useNavigate()
  const [airports, setAirports] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(() => {
    // Replace the current state with the home page
    window.history.replaceState(null, null, '/')

    // Listen for the `popstate` event and redirect to the home page
    const preventNavigation = () => {
      navigate('/', { replace: true })
      window.history.replaceState(null, null, '/')
    }

    window.addEventListener('popstate', preventNavigation)

    return () => {
      console.log(navigate)
      window.removeEventListener('popstate', preventNavigation)
    }
  }, [navigate])

  useEffect(() => {
    const fetchAirports = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/api/Airport/GetAllAirportDetails`,
          {
            method: 'GET',
            headers: {
              // "ngrok-skip-browser-warning": "true",
              'Content-Type': 'application/json',
            },
          }
        )
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()

        setAirports(data)
        setBookingData((prevData) => ({
          ...prevData,
          airportState: true,
          airports: data,
        }))
      } catch (error) {
        console.error('There was a problem with the fetch operation:', error)
      }
    }

    fetchAirports()
  }, [])

  // Default parking start and end dates
  useEffect(() => {
    if (!bookingData.parkingStart || !bookingData.parkingEnd) {
      const today = new Date()
      const tomorrow = new Date(today)
      tomorrow.setDate(today.getDate() + 1)
      const dayAfterTomorrow = new Date(today)
      dayAfterTomorrow.setDate(today.getDate() + 2)

      setBookingData((prevData) => ({
        ...prevData,
        parkingStart: tomorrow.toISOString().slice(0, 16),
        parkingEnd: dayAfterTomorrow.toISOString().slice(0, 16),
        totalCostAll: bookingData.totalCostAll || 0,
      }))
    }
  }, [bookingData, setBookingData])

  const validateForm = () => {
    const newErrors = {}
    const { parkingStart, parkingEnd, airportName } = bookingData

    if (!airportName) {
      newErrors.airportName = 'Please select a location.'
    }

    if (!parkingStart) {
      newErrors.parkingStart = 'Please select a parking start time.'
    }

    if (!parkingEnd) {
      newErrors.parkingEnd = 'Please select a parking end time.'
    }

    const startDate = new Date(parkingStart)
    const endDate = new Date(parkingEnd)

    if (startDate < new Date()) {
      newErrors.parkingStart = 'Parking start time cannot be in the past.'
    } else {
      // newErrors.parkingStart = "";
      // setErrors("");
    }

    if (startDate.getHours() < 4 || endDate.getHours() < 4) {
      newErrors.timeRestriction =
        'Selected parking times cannot be between 12 AM and 4 AM.'
    } else {
      // newErrors.timeRestriction = "";
    }

    if (endDate <= startDate) {
      newErrors.parkingEnd = 'Parking end time must be later than start time.'
    } else if (endDate - startDate < 3600000) {
      newErrors.parkingEnd =
        'Selected parking end time must be at least 1 hour after start time.'
    } else {
      // newErrors.parkingEnd = "";
    }

    setErrors(newErrors)

    return Object.keys(newErrors).length === 0
  }

  const handleOnAirportChange = (e) => {
    const selectedId = e.target.value
    const selectedAirport = airports.find(
      (airport) => airport.airportID === parseInt(selectedId, 10)
    )

    setBookingData((prevData) => ({
      ...prevData,
      airportId: selectedAirport?.airportID || '',
      airportName: selectedAirport?.airportName || '',
      airportCode: selectedAirport?.airportZipCode || '',
      totalCostAll: prevData.totalCostAll || 0,
    }))
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setBookingData((prevData) => ({
      ...prevData,
      [name]: value,
      totalCostAll: bookingData.totalCostAll || 0,
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateForm()) {
      const startDate = new Date(bookingData.parkingStart)
      const endDate = new Date(bookingData.parkingEnd)
      const differenceInDays = Math.ceil(
        (endDate - startDate) / (1000 * 60 * 60 * 24)
      )

      let cost = 78.9
      if (differenceInDays === 2) {
        cost += 1
      } else if (differenceInDays > 2) {
        cost += 7 * (differenceInDays - 2)
      }

      setBookingData((prevData) => ({
        ...prevData,
        totalDays: differenceInDays,
        totalDaysCost: cost,
        totalCostAll: bookingData.totalCostAll || cost,
      }))
      navigate('/booking_two')
    }
  }

  const handleSignUpClick = () => {
    // window.open("/signup");
    navigate('/signup')
  }

  useEffect(() => {
    setShowForm(true)
    const handleScroll = () => {
      if (window.scrollY > 590) {
        // Adjust this value to control when the button appears
        setShowForm(false)
      } else {
        setShowForm(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="container-fluid hero-bg-img" id="home">
      <div className="row home-row-height">
        <div className="col-lg-5 col-md-6 col-sm-12 text-center text-md-left left-section-hero text-box-hero-content">
          <img src={Icon} alt="Icon.png" className="bar-icon" />
          <h1 className="heading-placement">
            Welcome to
            <span className="span-color"> ASKAII </span> ||
            <br />
            Your Ultimate Car Parking Brand
          </h1>
          <p className="para-placement">
            Say goodbye to the stress of finding parking. Whether you’re heading
            to work, going for a short trip, or attending an event--ASKAII
            ensures you find and reserve the perfect spot quickly and easily.
          </p>
          <button
            className="btn btn-outline-success join-btn"
            type="button"
            onClick={handleSignUpClick}
          >
            Sign Up Now
          </button>
        </div>
        <div className="col-lg-7 col-md-6 col-sm-12 ">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-9 col-md-9 col-sm-9 form-bg-img"></div>
            {showForm && (
              <div className="col-lg-3 col-md-3 col-sm-3 form-shade">
                <form className="booking-form">
                  <div data-mdb-input-init className="form-outline mb-3">
                    <label htmlFor="Location">Location</label>
                    <select
                      className="rounded px-2 form-control"
                      id="airportSelect"
                      onChange={handleOnAirportChange}
                      value={bookingData.airportId || ''}
                    >
                      <option value="" disabled>
                        Select an airport
                      </option>
                      {bookingData.airports?.map((airport) => (
                        <option
                          key={airport.airportID}
                          value={airport.airportID}
                        >
                          {airport.airportName}
                        </option>
                      ))}
                    </select>
                    {errors.airportName && (
                      <small className="error-home">{errors.airportName}</small>
                    )}
                  </div>
                  <div data-mdb-input-init className="form-outline mb-3">
                    <label className="form-label" htmlFor="form4Example2">
                      Parking from
                    </label>
                    <input
                      name="parkingStart"
                      type="datetime-local"
                      className="form-control"
                      value={bookingData.parkingStart}
                      onChange={handleChange}
                      min={new Date().toISOString().slice(0, 16)}
                    />
                    {errors.parkingStart && (
                      <small className="error">{errors.parkingStart}</small>
                    )}
                  </div>
                  <div data-mdb-input-init className="form-outline mb-3">
                    <label className="form-label" htmlFor="form4Example2">
                      Parking to
                    </label>
                    <input
                      name="parkingEnd"
                      type="datetime-local"
                      className="form-control"
                      value={bookingData.parkingEnd}
                      onChange={handleChange}
                      min={new Date().toISOString().slice(0, 16)}
                    />
                    {errors.parkingEnd && (
                      <small className="error">{errors.parkingEnd}</small>
                    )}
                  </div>
                  {errors.timeRestriction && (
                    <small className="error">{errors.timeRestriction}</small>
                  )}
                  <div data-mdb-input-init className="form-outline mb-3">
                    <label className="form-label" htmlFor="form4Example1">
                      Discount code
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="discountCode"
                      placeholder="Enter your discount code"
                      value={bookingData.discountCode}
                      onChange={handleChange}
                    />
                  </div>
                  <button
                    data-mdb-ripple-init
                    type="button"
                    className="btn btn-primary btn-block mb-4 quote-btn"
                    onClick={handleSubmit}
                  >
                    Quote
                  </button>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
    // <div className="hero_main_container">
    //   <div className="hero_child_container">
    //     <div className="hero_left">
    //       <img src={Icon} alt="Icon.png" />
    //       <h1>
    //         Welcome to <span>ASKAII</span> || <br /> Your Ultimate Car <br />{' '}
    //         Parking Brand
    //       </h1>
    //       <p>
    //         Say goodbye to the stress of finding parking. Whether you’re heading
    //         to work, going for a short trip, or attending an event, ASKAII
    //         ensures you find and reserve the perfect spot quickly and easily.
    //       </p>
    //       <button type="button" onClick={handleSignUpClick}>
    //         {' '}
    //         Sign Up Now
    //       </button>
    //     </div>
    //     <div className="hero_right">
    //       <img src={right_image} alt="" />
    //       {showForm && (
    //         <div className="booking_from_parent">
    //           <form className="booking-form">
    //             <div data-mdb-input-init className="form-outline mb-3">
    //               <label htmlFor="Location">Location</label>
    //               <select
    //                 className="rounded px-2 form-control"
    //                 id="airportSelect"
    //                 onChange={handleOnAirportChange}
    //                 value={bookingData.airportId || ''}
    //               >
    //                 <option value="" disabled>
    //                   Select an airport
    //                 </option>
    //                 {bookingData.airports?.map((airport) => (
    //                   <option key={airport.airportID} value={airport.airportID}>
    //                     {airport.airportName}
    //                   </option>
    //                 ))}
    //               </select>
    //               {errors.airportName && (
    //                 <small className="error-home">{errors.airportName}</small>
    //               )}
    //             </div>
    //             <div data-mdb-input-init className="form-outline mb-3">
    //               <label className="form-label" htmlFor="form4Example2">
    //                 Parking from
    //               </label>
    //               <input
    //                 name="parkingStart"
    //                 type="datetime-local"
    //                 className="form-control"
    //                 value={bookingData.parkingStart}
    //                 onChange={handleChange}
    //                 min={new Date().toISOString().slice(0, 16)}
    //               />
    //               {errors.parkingStart && (
    //                 <small className="error">{errors.parkingStart}</small>
    //               )}
    //             </div>
    //             <div data-mdb-input-init className="form-outline mb-3">
    //               <label className="form-label" htmlFor="form4Example2">
    //                 Parking to
    //               </label>
    //               <input
    //                 name="parkingEnd"
    //                 type="datetime-local"
    //                 className="form-control"
    //                 value={bookingData.parkingEnd}
    //                 onChange={handleChange}
    //                 min={new Date().toISOString().slice(0, 16)}
    //               />
    //               {errors.parkingEnd && (
    //                 <small className="error">{errors.parkingEnd}</small>
    //               )}
    //             </div>
    //             {errors.timeRestriction && (
    //               <small className="error">{errors.timeRestriction}</small>
    //             )}
    //             <div data-mdb-input-init className="form-outline mb-3">
    //               <label className="form-label" htmlFor="form4Example1">
    //                 Discount code
    //               </label>
    //               <input
    //                 type="text"
    //                 className="form-control"
    //                 name="discountCode"
    //                 placeholder="Enter your discount code"
    //                 value={bookingData.discountCode}
    //                 onChange={handleChange}
    //               />
    //             </div>
    //             <button
    //               data-mdb-ripple-init
    //               type="button"
    //               className="btn btn-primary btn-block mb-4 quote-btn"
    //               onClick={handleSubmit}
    //             >
    //               Quote
    //             </button>
    //           </form>
    //         </div>
    //       )}
    //     </div>
    //   </div>
    // </div>
  )
}
