// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import './App.css';
// import NavBar from './Components/NavBar/navbar';
// import LoginForm from './Components/Forms/login';
// import SignupForm from './Components/Forms/signup';
// import Home from './Components/Home/home';
// import Booking from './Components/Booking/booking';
// import Services from './Components/Services/Services';
// import Team from './Components/OurTeam/Team';
// import ImageDesc from './Components/ImageDesc/imageDesc';
// import Testimonial from './Components/Testimonials/testimonial';
// import FAQs from './Components/FAQs/faq';
// import FAQAccordian from './Components/FAQs/accordian';
// import NewsLetter from './Components/NewsLetter/news';
// import Find from './Components/FindUs/Find';
// import Footer from './Components/Footer/footer';
// import VideoDesc from './Components/VideoDesc/videoDesc';
// // import Privacy from './Components/Privacy/Privacy';
//   // import Merge from './Components/Mergecomponent/Mergecomponent';
//   // import Merge2 from './Components/Mergecomponent/Mergetermscomponents';
//   // import Terms from './Components/Terms/Terms'

// function App() {
//   return (
//     <Router>
//       <div>
//         <NavBar />
//         <Routes>
//           <Route path="/" element={<HomePage />} />
//           <Route path="/login" element={<LoginForm />} />
//           <Route path="/signup" element={<SignupForm />} />
//         </Routes>
//       </div>
//     </Router>
//   );
// }

// function HomePage() {
//   return (
//     <>
//       <Home />
//       <Booking />
//       <Services />
//       <ImageDesc />
//       <Team />
//       <VideoDesc />
//       <Testimonial />
//       <FAQs />
//       <FAQAccordian />
//       <NewsLetter />
//       <Find />
//       <Footer />
//       {/* <Privacy/> */}
//       {/* <Merge/> */}
//       {/* <Terms/> */}
//       {/* <Merge2/> */}
//     </>
//   );
// }

// export default App;

import React from "react";
import "./App.css";
import AllComponents from "./Components/MergeAllComponents/AllComponents";
import DashboardComponents from "./Dashboard/DashBoardComponents/DashBoardComponents.jsx";
// import Bookingthree from "./Components/Booking/Bookingg_three.jsx";
// import Test from './Components/MergeAllComponents/MergePrivacyComponent';
// import Test from './Components/MergeAllComponents/MergeTermsComponents';

export default function App() {
  return (
    <div>
      <AllComponents />
      {/* <DashboardComponents /> */}
      {/* <Bookingthree /> */}
      {/* <Test /> */}
    </div>
  );
}
