import React, { useState, useEffect } from 'react'
import './../Footer/footer.css'
import logo from './../../assets/Skaii_Logo_Final-1-removebg-preview.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { API_BASE_URL } from '../../Config/Config'

export default function Footer() {
  const [showBackToTop, setShowBackToTop] = useState(false)
  const [isSubscribed, setIsSubscribed] = useState(false)

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
    email: '',
    subject: '',
    message: '',
  })

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target

    // If the email field is being updated, extract the mobile
    // if (name === 'email') {
    //   const nameMatch = value.match(/^([^@]*)@/)
    //   const mobile = nameMatch ? nameMatch[1] : value

    //   // Update both email and mobile fields
    //   setFormData((prevFormData) => ({
    //     ...prevFormData,
    //     email: value,
    //     mobile: mobile, // auto-set mobile from email
    //   }))
    // } else {
    // For other form fields, update as usual
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    })
    // }
  }

  const handleSubscribe = async (e) => {
    e.preventDefault()
    const newContact = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.mobile,
      message: formData.message,
      subject: formData.subject,
    }
    console.log(newContact)

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/ContactUs/CerateContactUs`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newContact),
        }
      )

      if (response.ok) {
        const createdContact = await response.json()
        console.log(createdContact)
        setFormData({
          firstName: '',
          lastName: '',
          mobile: '',
          email: '',
          subject: '',
          message: '',
        })
        setIsSubscribed(true)
      }
    } catch (error) {}
    // console.log(firstName, lastName, email, phone, message, subject, date)
  }

  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 1200) {
        setShowBackToTop(true)
      } else {
        setShowBackToTop(false)
      }
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="container-fluid footer-bg" id="footer">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12"></div>
        <div className="col-lg-4 col-md-4 col-sm-12 text-center">
          <img src={logo} className="logo-reso" alt="logo.png" />
          <h1 className="subscribe-heading footer-slogan">
            Relax and soar, your vehicle is secure.
          </h1>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12"></div>
      </div>
      <div className="row subscribe-row">
        <div className="col-lg-4 col-md-4 col-sm-12"></div>
        <div className="col-lg-4 col-md-4 col-sm-12 text-center">
          <h1 className="subscribe-heading">
            Let's be a part of askaii family.
          </h1>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12"></div>
      </div>
      <div className="row form-placement">
        <div className="registration-container-footer">
          <form
            className="registration-form contact-us-form"
            onSubmit={handleSubscribe}
          >
            <h2>Contact Us</h2>

            <div className="form-row">
              <div className="form-column">
                <label>
                  First Name:
                  <input
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              <div className="form-column">
                <label>
                  Last Name:
                  <input
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
            </div>
            <div className="form-row">
              <div className="form-column">
                <label>
                  Email: *
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              <div className="form-column">
                <label>
                  Phone No: *
                  <input
                    type="text"
                    name="mobile"
                    onChange={handleInputChange}
                    value={formData.mobile}
                    required
                  />
                  {/* <span onClick={handleCopyToClipboard}>
                    {formData.email ? <FaRegClipboard /> : ''}
                  </span>
                  {copySuccess && (
                    <span style={{ marginRight: '20px' }}>{copySuccess}</span>
                  )} */}
                </label>
              </div>
            </div>

            <div className="form-row">
              <div className="form-column">
                <label>
                  Subject:
                  <input
                    type="text"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              <div className="form-column">
                <label className="form-column">
                  Message: *
                  {/* <input
                    type="textarea"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  /> */}
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows="4"
                    cols="39"
                    className="form-control"
                  ></textarea>
                </label>
              </div>
            </div>

            <button type="submit">Submit</button>
            {isSubscribed && (
              <p className="user-p-tag">
                Thank you for subscribing! We will reach out to you with
                relevant information about our parking services. Stay tuned!
              </p>
            )}
          </form>
        </div>
        {/* <div className="col-lg-4 col-md-4 col-sm-12">
          <form>
            <div className="row foter-row">
              <div className="col-lg-7 col-md-7 col-sm-8 foter-input">
                <input
                  type="email"
                  className="form-control email-input"
                  id="exampleInputEmail1"
                  placeholder="Your Email"
                  aria-describedby="emailHelp"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 foter-button">
                <button
                  className="btn btn-primary subscribe-btn"
                  type="button"
                  onClick={handleLoginClick}
                  style={{ backgroundColor: loginButtonColor }}
                >
                  Subscribe
                </button>
              </div>
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            {isSubscribed && (
              <p className="error-message">
                Thank you for subscribing! We will reach out to you with
                relevant information about our parking services. Stay tuned!
              </p>
            )}
          </form>
        </div> */}
        {/* <div className="col-lg-4 col-md-4 col-sm-12"></div> */}
      </div>
      <div className="row links-placement">
        <div className="col-lg-4 col-md-4 col-sm-12"></div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4 vertical-separater">
              <Link to="/" className="footer-links">
                Home
              </Link>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 vertical-separater">
              <Link to="/privacy-policy" className="footer-links">
                Privacy Policy
              </Link>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <Link to="/terms-conditions" className="footer-links">
                Terms and Conditions
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12"></div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12"></div>
        <div className="col-lg-4 col-md-4 col-sm-12 text-center">
          <h1 className="copyright-text">
            Designed & developed by{' '}
            <a href="https://huboweb.com/" target="_blank" rel="noreferrer">
              HuboWeb LLC
            </a>{' '}
          </h1>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 text-center">
          {showBackToTop && (
            <button className="btn back-to-top" onClick={handleBackToTop}>
              <FontAwesomeIcon icon={faArrowUp} />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
