import React from "react";

const NewWindowTAC = () => {
  const width = window.screen.width;
  const height = window.screen.height;
  const handleClick = () => {
    const newWindow = window.open(
      "/terms-and-conditions",
      "_blank",
      `noopener,noreferrer,width=${width},height=${height}`
    );

    if (newWindow) {
      newWindow.focus();
    }
  };
  return (
    <div className="main-text">
      <span className="main-link" onClick={handleClick}>
        T&Cs{" "}
      </span>
      Cancellations free 24 hours before booking time. Admin fees apply for
      cancellations. See
    </div>
  );
};

export default NewWindowTAC;
