import React, { useState } from 'react'
import { useBooking } from './../BookingContext/DataContext'
import './Booking2.css'
import { NavLink, useNavigate } from 'react-router-dom'
import OpenNewWindow from './ExampleComp/NewWindow'
import { API_BASE_URL } from '../../Config/Config'
import BookingLoader from './../BookingLoader/LoadingAndError'

const Booking2 = () => {
  const { bookingData, setBookingData } = useBooking()
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)
  const navigate = useNavigate()

  const handleBack = () => {
    navigate('/')
  }

  // console.log(bookingData.airportId)
  // console.log(bookingData.parkingStart)
  // console.log(bookingData.parkingEnd)

  const handleNext = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${API_BASE_URL}/api/Booking/Quote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // "ngrok-skip-browser-warning": "true",
        },
        body: JSON.stringify({
          airportId: bookingData.airportId,
          startDateTime: bookingData.parkingStart,
          endDateTime: bookingData.parkingEnd,
          discountCode: bookingData.discountCode,
        }),
      })

      if (!response.ok) {
        throw new Error('Failed to fetch quote')
      }
      const data = await response.json()
      console.log(data)

      setBookingData((prevData) => ({
        ...prevData,
        quoteCode: data.quoteCode,
        totalCostAll: bookingData.totalCostAll,
      }))
      setLoading(false)
      navigate('/booking_three')
    } catch (err) {
      setError(err.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading && <BookingLoader />}
      <div className="booking2-container-background">
        <div className="booking-navbar-internal">
          <ul className="internal-navbar">
            <li>
              <NavLink to="/">Back to Home</NavLink>
            </li>
            <li className="nabar-item-1">
              <a className="active1" to="#news">
                Get a Quote
              </a>
            </li>
            <li className="nabar-item-2">
              <a href="#contact"> Available Products</a>
            </li>
            <li className="nabar-item-3">
              <a href="#about">Products</a>
            </li>
            <li className="nabar-item-4">
              <a href="#about">Booking Details</a>
            </li>
            <li className="nabar-item-5">
              <a href="#about">Payment</a>
            </li>
            <li className="nabar-item-6">
              <a href="#about">Payment Details</a>
            </li>
          </ul>
        </div>
        <div className="booking2-container">
          {/* <div className="status">
          <span>Status: </span>{" "}
          <span>
            {" "}
            <b>Get a Quote</b>{" "}
          </span>
          &gt;
          <span> Available Products </span>&gt;<span> Product Options </span>
          &gt;
          <span> Booking Details </span>
        </div> */}
          <div className="section booking-details">
            <h2>Booking Details</h2>
            <div className="field">
              <label htmlFor="location">Location:</label>
              <span id="location">{bookingData.airportName}</span>
            </div>
            <div className="field-site">
              <label className="site-box-field" htmlFor="site">
                Site:
              </label>
              <OpenNewWindow />
            </div>
            <div className="field">
              <label htmlFor="parking-start">Parking Start:</label>
              <span>{bookingData.parkingStart}</span>
            </div>
            <div className="field">
              <label htmlFor="parking-end">Parking End:</label>
              <span>{bookingData.parkingEnd}</span>
            </div>
          </div>
          <div className="section discount">
            <h2>Discount</h2>
            <div className="field">
              <label htmlFor="discount-code">Discount Code:</label>
              <input
                type="text"
                id="discount-code"
                value={bookingData.discountCode}
                readOnly
              />
            </div>
            <span className="make-sure-2">
              If you have a discount code enter it here.
            </span>
          </div>

          <div className="booking2-btn">
            <button className="booking-button" onClick={handleBack}>
              Back
            </button>
            <button className="booking-button" onClick={handleNext}>
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Booking2
