import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import './../Forms/login.css'
import { NavLink, useLocation } from 'react-router-dom'
import { API_BASE_URL } from '../../Config/Config'
import Cookies from 'js-cookie'
import BookingLoader from '../BookingLoader/LoadingAndError'

export default function Login() {
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const role = searchParams.get(`role`)

  async function handleLogin(e) {
    e.preventDefault()
    setLoading(true)

    // Basic validation for empty fields
    if (!userName || !password) {
      setLoading(false)
      alert('Please fill in both username and password.')
      return
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/User/Login?usertype=${role}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userName: userName,
            password: password,
          }),
        }
      )
      const data = await response.json()
      console.log(data)
      if (!response.ok) {
        throw new Error('Invalid credentials')
      }
      // console.log(data)

      // // Check if login was successful by verifying the token or userID
      // if (data.jwtToken) {
      //   // Redirect to the external dashboard link
      //   // window.location.href = `http://dashboard.askaii.co.uk/?${data.jwtToken}`
      //   window.location.href = `http://localhost:3001/?token=${data.jwtToken}`
      // } else {
      //   throw new Error('Login failed')
      // }
      if (response.ok) {
        // window.location.href = `http://localhost:3001/?token=${data.jwtToken}`

        // Store the token in cookies (with secure and SameSite=None)
        Cookies.set(
          'userSession',
          JSON.stringify({
            tokenID: data.tokenID,
            authToken: data.jwtToken,
            userId: data.userID,
          }),
          {
            secure: true, // Set to true when using HTTPS
            sameSite: 'None', // Required for cross-origin cookies
            domain: 'localhost', // Can be omitted for localhost
            path: '/', // Ensure it is available in all paths
          }
        )
        setLoading(false)

        // Redirect to dashboard
        window.location.href = 'http://localhost:3001'
      } else {
        setLoading(false)
        alert('Invalid credentials')
      }
    } catch (err) {
      setLoading(false)
      alert('Invalid credentials. Please try again.')
    }
  }

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  return (
    <>
      {loading && <BookingLoader />}

      <form onSubmit={handleLogin}>
        <div className="login-container">
          <div className="login-form">
            <h2>Login</h2>

            <label htmlFor="exampleInputEmail1" className="form-label">
              <b>Username:</b>
              <input
                type="text"
                className="form-control login-form-new"
                placeholder="Enter your username"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </label>

            <label htmlFor="exampleInputEmail1" className="form-label">
              <b>Password:</b>
              <input
                type={passwordVisible ? 'text' : 'password'}
                className="form-control login-form-new"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
                required
              />
            </label>

            <button type="submit" className="btn btn-primary">
              Login
            </button>
            <p>
              <NavLink to="/signup">Forget Password? </NavLink>
            </p>
            {role === 'SuperAdmin' ? (
              ''
            ) : (
              <p>
                Not a member? <NavLink to="/signup">Register</NavLink>
              </p>
            )}
          </div>
        </div>
      </form>
    </>
  )
}
