import React, { useState } from 'react'
import { FaEye, FaEyeSlash, FaRegClipboard } from 'react-icons/fa'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import './signup.css'
import { API_BASE_URL } from '../../Config/Config'
import BookingLoader from '../BookingLoader/LoadingAndError'

export default function Register() {
  const [discoundCode, setDiscountCode] = useState()
  const [copySuccess, setCopySuccess] = useState('')
  const [passwordVisible, setPasswordVisible] = useState(false)
  const [loading, setLoading] = useState(null)
  const [error, setError] = useState(null)
  const togglePasswordVisibility = () => setPasswordVisible(!passwordVisible)
  const navigate = useNavigate()

  // const location = useLocation()
  // const searchParams = new URLSearchParams(location.search)
  // const role = searchParams.get('role')

  // var name = str.match(/^([^@]*)@/)[1]

  const [formData, setFormData] = useState({
    userTitle: '',
    firstName: '',
    lastName: '',
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    mobile: '',
    companyName: '',
    promotion: false,
    agreeTerms: false,
  })
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target

    // If the email field is being updated, extract the username
    if (name === 'email') {
      const nameMatch = value.match(/^([^@]*)@/)
      const userName = nameMatch ? nameMatch[1] : value

      // Update both email and username fields
      setFormData((prevFormData) => ({
        ...prevFormData,
        email: value,
        userName: userName, // auto-set username from email
      }))
    } else {
      // For other form fields, update as usual
      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      })
    }
  }
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(formData.userName)
      setCopySuccess('Copied!')
    } catch (err) {
      setCopySuccess('Failed to copy')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const {
      // userTitle,
      firstName,
      lastName,
      userName,
      email,
      password,
      confirmPassword,
      mobile,
      companyName,
      promotion,
      agreeTerms,
    } = formData

    if (!agreeTerms) {
      alert('You must agree to the terms and conditions')
      return
    }

    if (password !== confirmPassword) {
      alert('Passwords do not match!')
      return
    }

    const userData = {
      userName: userName,
      password: password,
      firstName: firstName,
      lastName: lastName,
      email: email,
      companyName: companyName,
      promotion: promotion,
      phoneNo: mobile,
      // userTypeID: 3,
    }

    console.log(userData)
    setLoading(true)
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/User/Register?usertype=3`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(userData),
        }
      )
      const result = await response.json()
      console.log(result)
      if (response.ok) {
        // alert('Registration successful!')
        navigate('/login?role=Customer')
      } else {
        alert(`Registration failed: ${result.message}`)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      alert('An error occurred during registration.')
      console.error('Registration error:', error)
    }
  }

  return (
    <>
      {loading && <BookingLoader />}
      <div className="registration-container">
        <form className="registration-form" onSubmit={handleSubmit}>
          <h2>Customer Register Form</h2>

          <div className="form-row">
            <div className="form-column">
              <label>
                Title:
                <br />
                <select
                  name="userTitle"
                  value={formData.userTitle}
                  onChange={handleInputChange}
                >
                  <option value="" disabled>
                    Select an Option
                  </option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                </select>
              </label>
            </div>

            <div className="form-column">
              <label>
                First Name: *
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="form-column">
              <label>
                Surname:
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
          </div>
          <div className="form-row">
            <div className="form-column">
              <label>
                Email: *
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="form-column">
              <label>
                User Name: *
                <input
                  type="text"
                  name="userName"
                  onChange={handleInputChange}
                  value={formData.userName}
                  readOnly
                />
                <span onClick={handleCopyToClipboard}>
                  {formData.email ? <FaRegClipboard /> : ''}
                </span>
                {copySuccess && (
                  <span style={{ marginRight: '20px' }}>{copySuccess}</span>
                )}
              </label>
            </div>
          </div>

          <div className="form-row">
            <div className="form-column">
              <label>
                Password: *
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
                />
                <span onClick={togglePasswordVisibility}>
                  {formData.password ? (
                    passwordVisible ? (
                      <FaEyeSlash />
                    ) : (
                      <FaEye />
                    )
                  ) : (
                    ''
                  )}
                </span>
              </label>
            </div>

            <div className="form-column">
              <label>
                Confirm Password: *
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required
                />
                <span onClick={togglePasswordVisibility}></span>
              </label>
            </div>
          </div>
          <div className="form-row">
            <div className="form-column">
              <label>
                Mobile: *
                <input
                  type="tel"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  required
                />
              </label>
            </div>
            <div className="form-column">
              <label>
                Company Name:
                <input
                  type="text"
                  name="companyName"
                  value={formData.companyName}
                  onChange={handleInputChange}
                />
              </label>
            </div>
          </div>

          <div className="form-row">
            <label>Receive Promotions: </label>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                name="promotion"
                checked={formData.promotion}
                onChange={handleInputChange}
              />
              <label className="form-check-label">Yes</label>
            </div>
          </div>

          <label className="terms">
            <input
              type="checkbox"
              name="agreeTerms"
              checked={formData.agreeTerms}
              onChange={handleInputChange}
              required
            />
            Please tick to confirm you have read and agree to the terms and
            conditions. *
          </label>

          <button type="submit">Submit</button>
        </form>
      </div>
    </>
  )
}
