import React, { useEffect, useState } from "react";
import "./Booking6.css";
import { useNavigate } from "react-router-dom";
import { useBooking } from "../BookingContext/DataContext";
import { API_BASE_URL } from "../../Config/Config";
import { NavLink } from "react-router-dom";
import BookingLoader from "../BookingLoader/LoadingAndError";

const Booking6 = () => {
  const { bookingData, setBookingData } = useBooking();
  const [countries, setCountries] = useState();
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/booking_five");
  };

  const handleCreatePaypal = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/Booking/CreatePayment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            // amount: bookingData.totalCostAll,
            amount: 5,
            currency: "GBP",
            returnUrl: "http://localhost:3000/return-url",
            cancelUrl: "http://localhost:3000/cancel-url",
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch quote");
      }
      const data = await response.json();
      setLoading(false);

      const width = window.screen.width;
      const height = window.screen.height;
      const newWindow = window.open(
        data.links[1].href,
        "_blank",
        `noopener,noreferrer,width=${width},height=${height}`
      );

      if (newWindow) {
        newWindow.focus();
      }
    } catch (err) {
      // setError(err.message);
      setLoading(false);
    } finally {
      setLoading(false);
      // setLoading(false);
    }
    navigate("/");
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(
          `https://restcountries.com/v3.1/all?fields=name`,
          {
            method: "GET",
            headers: {
              // "ngrok-skip-browser-warning": "true",
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchCountries();
  }, []);

  const handlePaymentChange = (e) => {
    const { name, type, value, checked } = e.target;
    setBookingData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox"
          ? checked
          : value === "true"
          ? true
          : value === "false"
          ? false
          : value,
    }));
  };

  const handleBillingAddress = (e) => {
    const { name, value } = e.target;
    setBookingData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!bookingData.postCode) formErrors.postCode = "Postcode is required";
    if (!bookingData.address) formErrors.address = "Address is required";
    if (!bookingData.town) formErrors.town = "Town/City is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      navigate("/booking_seven");
    }
  };

  return (
    <div className="booking6-container-background">
      {loading && <BookingLoader />}
      <div className="booking-navbar-internal">
        <ul className="internal-navbar">
          <li>
            <NavLink to="/">Back to Home</NavLink>
          </li>
          <li className="nabar-item-1-booking-6">
            <a className="active1" to="#news">
              Get a Quote
            </a>
          </li>
          <li className="nabar-item-2-booking-6">
            <a href="#contact"> Available Products</a>
          </li>
          <li className="nabar-item-3-booking-6">
            <a href="#about">Products</a>
          </li>
          <li className="nabar-item-4-booking-6">
            <a href="#about">Booking Details</a>
          </li>
          <li className="nabar-item-5-booking-6">
            <a href="#about">Payment</a>
          </li>
          <li className="nabar-item-6-booking-6">
            <a href="#about">Payment Details</a>
          </li>
        </ul>
      </div>
      <div className="booking6-container">
        <h2 className="booking6-header">Payment</h2>
        <form>
          <div className="booking6-detail-item">
            <strong>Transaction Type:</strong>
            <span>Booking</span>
          </div>
          <div className="booking6-detail-item total-cost-item6">
            <strong>Total Cost:</strong>
            <span>£{bookingData.totalCostAll}</span>
          </div>
          <div className="booking6-detail-item">
            <strong>Payment Method:</strong>
            <div className="Payment-Method-box">
              <label>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="card"
                  onChange={handlePaymentChange}
                  checked={bookingData.paymentMethod === "card"}
                />{" "}
                Card
              </label>
              <br />
              <label>
                <input
                  type="radio"
                  name="paymentMethod"
                  value="paypal"
                  onChange={handlePaymentChange}
                  checked={bookingData.paymentMethod === "paypal"}
                />{" "}
                PayPal
              </label>
            </div>
          </div>
          {bookingData.paymentMethod === "card" && (
            <div className="payment-method-1">
              <h2 className="booking6-header">Billing Address</h2>
              <div className="billing-address-section6">
                <div className="booking6-detail-item">
                  <strong>Postcode:</strong>
                  <input
                    className="booking6-input-size"
                    type="text"
                    name="postCode"
                    value={bookingData.postCode}
                    onChange={handleBillingAddress}
                  />
                  <div className="error-booking6">*</div>
                  {errors.postCode && (
                    <span className="error-bookingsix">{errors.postCode}</span>
                  )}
                </div>
                <div className="booking6-detail-item">
                  <strong>Address:</strong>
                  <textarea
                    className="booking6-input-size"
                    name="address"
                    value={bookingData.address}
                    onChange={handleBillingAddress}
                  ></textarea>
                  <div className="error-booking6">*</div>
                  {errors.address && (
                    <span className="error-bookingsix">{errors.address}</span>
                  )}
                </div>
                <div className="booking6-detail-item">
                  <strong>Town/City:</strong>
                  <input
                    className="booking6-input-size"
                    type="text"
                    name="town"
                    value={bookingData.town}
                    onChange={handleBillingAddress}
                  />
                  <div className="error-booking6">*</div>
                  {errors.town && (
                    <span className="error-bookingsix">{errors.town}</span>
                  )}
                </div>
                <div className="booking6-detail-item">
                  <strong>County:</strong>
                  <input
                    className="booking6-input-size"
                    type="text"
                    name="county"
                    value={bookingData.county}
                    onChange={handleBillingAddress}
                  />
                </div>
                <div className="booking6-detail-item">
                  <strong>Country:</strong>
                  <select
                    className="booking6-input-size"
                    name="country"
                    value={bookingData.country}
                    onChange={handleBillingAddress}
                  >
                    {countries?.map((country, index) => (
                      <option
                        selected={country.name.common === "United Kingdom"}
                        key={index}
                      >
                        {country.name.common}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="actions6">
                <button type="button" onClick={handleBack}>
                  Previous
                </button>
                <button type="button" onClick={handleNext}>
                  Continue
                </button>
              </div>
            </div>
          )}
          {bookingData.paymentMethod === "paypal" && (
            <div className="payment-method-2">
              <h2 className="booking6-header">PayPal</h2>
              <div className="booking6-detail-item total-cost-item6">
                <strong>Total Cost:</strong>
                <span>{bookingData.totalCostAll}</span>
              </div>
              <div className="booking6-detail-item total-cost-item6">
                <strong>Currency:</strong>
                <span>GBP( £)</span>
              </div>
              <div className="payPal-main-box">
                <div className="payPal-main-box1">
                  <div className="actions8">
                    <button type="button" onClick={handleBack}>
                      Back
                    </button>
                  </div>
                </div>
                <div className="payPal-main-box2">
                  <div className="actions8">
                    <button type="button" onClick={handleCreatePaypal}>
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Booking6;
