import React, { createContext, useState, useContext, useEffect } from 'react'
import Cookies from 'js-cookie'

const BookingContext = createContext()

export function useBooking() {
  return useContext(BookingContext)
}

export function BookingProvider({ children }) {
  const [bookingData, setBookingData] = useState(() => {
    const savedEmail = localStorage.getItem('currentEmail')
    const savedBookingData = savedEmail
      ? JSON.parse(localStorage.getItem(savedEmail))
      : null
    return (
      savedBookingData || {
        airportState: false,
        airports: [],

        airportName: '',
        airportId: '',
        airportCode: '',
        parkingStart: '',
        parkingEnd: '',
        discountCode: '',
        totalDays: 0,
        totalDaysCost: 0,
        totalCostAll: 0,
        quoteCode: '',
        email: '',
        registerOption: 'Login',

        outboundTerminals: [],
        inboundTerminals: [],
        terminalState: false,

        carCleanService: [],
        electricChargeService: [],
        serviceState: false,

        outBoundTerminalName: '',
        outBoundTerminalId: '',
        outBoundTerminalCost: 0,

        inBoundTerminalName: '',
        inBoundTerminalId: '',
        inBoundTerminalCost: 0,

        carCleanName: 'None',
        carCleanId: null,
        carCleanCost: 0,

        electricChargeName: 'None',
        electricChargeId: null,
        electricChargeCost: 0,

        userIsRegister: false,
        promotions: false,
        userTitle: '',
        userFirstName: '',
        userSurName: '',
        userCompanyName: '',
        userMobileNo: '',

        userSource: '',
        otherUserSource: '',

        carRegistrationNo: '',

        allMake: [],
        carMakeName: '',
        carMakeId: '',

        carOtherMakeName: '',
        carOtherMakeId: '',

        allModel: [],
        carModelName: '',
        carModelId: '',

        carOtherModelName: '',
        carOtherModelId: '',

        allColour: [],
        carColourName: '',
        carColourId: '',

        carOtherColourName: '',
        carOtherColourId: '',

        flyingTo: '',
        outBoundFlightNo: '',
        inBoundFlightNo: '',
        isDifferentFromAirport: false,
        flyingFrom: '',
        isAgreeOnTerms: '',

        paymentMethod: 'card',

        postCode: '',
        address: '',
        town: '',
        county: '',
        country: 'United Kingdom',

        paymentState: '',

        // creditCard: {
        //   name: "",
        //   cardNumber: "",
        //   expirationYear: "",
        //   expirationMonth: "",
        //   cvc: "",
        // },

        customerId: '',
        toggleDetails: false,
      }
    )
  })

  const [cardData, setCardData] = useState({
    creditCard: {
      name: '',
      cardNumber: '',
      expirationYear: '',
      expirationMonth: '',
      cvc: '',
    },
  })
  // useEffect(() => {
  //   if (bookingData.email) {
  //     Cookies.set("bookingData", JSON.stringify(bookingData), {
  //       expires: 7, // Expires in 7 days
  //     });
  //   }
  // }, [bookingData]);

  // // Check if the email changes and load new data
  // useEffect(() => {
  //   const savedBookingData = Cookies.get("bookingData");
  //   if (savedBookingData) {
  //     const parsedData = JSON.parse(savedBookingData);
  //     if (parsedData.email !== bookingData.email) {
  //       setBookingData(parsedData); // Load the new data
  //     }
  //   }
  // }, [bookingData.email]);
  useEffect(() => {
    if (bookingData.email) {
      localStorage.setItem('currentEmail', bookingData.email)
      localStorage.setItem(bookingData.email, JSON.stringify(bookingData))
    }
  }, [bookingData])

  // If the email changes, load new booking data from localStorage
  useEffect(() => {
    const savedBookingData = localStorage.getItem(bookingData.email)
    if (savedBookingData) {
      setBookingData(JSON.parse(savedBookingData))
    }
  }, [bookingData.email])

  return (
    <BookingContext.Provider
      value={{ bookingData, setBookingData, cardData, setCardData }}
    >
      {children}
    </BookingContext.Provider>
  )
}
